import React from "react";
import {
  Box,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  
} from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import { DollarIcon } from "../../icons/Dollar"
import { Home } from "../../icons/Home";
import { ChartNetwork } from "../../icons/ChartNetwork";
import { Bullhorn } from "../../icons/Bullhorn";
import { SendBackward } from "../../icons/SendBackward";
import { ChartPie } from "../../icons/ChartPie";
import { FileChartLine } from "../../icons/FileChartLine";
import { QuestionSquare } from "../../icons/QuestionSquare";
import { Cog } from "../../icons/Cog";
import { Gem } from "../../icons/Gem";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const mainMenuItems = [
  {
    display: "Dashboard",
    icon: <Home />,
    path: "/",
    section: "dashboard",
    active: true,
  },
  {
    display: "Influencers Selection",
    icon: <ChartNetwork />,
    path: "/influencers-selection",
    section: "influencers-selection",
    active: true,
  },
  {
    display: "Follower list info",
    icon: <GroupsIcon sx={{color: "white"}}/>,
    path: "/followerList",
    section :"follower-list-info",
    active: true,
  },

  // {
  //   display: "Campaigns Tracker",
  //   icon: <Bullhorn />,
  //   path: "/campaigns-tracker",
  //   section: "campaigns-tracker",
  //   active: false,
  // },
  // {
  //   display: "Competitor Tracker",
  //   icon: <SendBackward />,
  //   path: "/competitor-tracker",
  //   section: "competitor-tracker",
  //   active: false,
  // },
  // {
  //   display: "Market Analysis",
  //   icon: <ChartPie />,
  //   path: "/market-analysis",
  //   section: "market-analysis",
  //   active: false,
  // },
  {
    display: "Reports",
    icon: <FileChartLine />,
    path: "/reports",
    section: "reports",
    active: true,
  },
];

const generalItems = [
  {
    display: "Support",
    icon: <QuestionSquare />,
    path: "https://binro.io/support",
    section: "support",
    active: true,
  },
  {
    display: "Settings",
    icon: <Cog />,
    path: "/settings",
    section: "settings",
    active: true,
  },
  {
    display: "Credits",
    icon : <DollarIcon />,
    path: "/packages",
    section: "packages",
    active: true,
  },
  {
    display: "Demo",
    icon: <Gem />,
    path: "https://binro.io/demo",
    section: "demo",
    active: true,
  },
];

const Sidebar = (props) => {
  const { window, sidebarWidth } = props;
  const location = useLocation();

  const drawer = (
    <Box>
      <Box
        sx={{
          px: "10px",
          py: "30px",
          width: "120px",
        }}
      >
        <img
          style={{ width: "100%", objectFit: "contain" }}
          src={logo}
          alt="logo"
        />
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: "11px",
            textTransform: "uppercase",
            color: "#969696",
            px: "10px",
          }}
        >
          Main Menu
        </Typography>
        <List>
          {mainMenuItems.map((item, index) => (
            <ListItem sx={{ px: "10px", pb: "5px" }} key={index} disablePadding>
              <ListItemButton
                component={Link}
                to={item.path}
                selected={
                  item.path.split("/")[1] === location.pathname.split("/")[1]
                }
                sx={{
                  p: "10px",
                  borderRadius: "8px",
                  color: "#fff",
                  fill: "#fff",
                  justifyContent: "space-between",
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                    fontWeight: "500",
                  },
                  "&.Mui-disabled": {
                    opacity: "1!important",
                  },
                }}
                disabled={!item.active}
              >
                <Box display="flex" alignItems="center">
                  <ListItemIcon sx={{ minWidth: "34px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      ".MuiTypography-root": {
                        fontSize: "15px",
                      },
                      lineHeight: "1.5rem",
                      fontWeight: 400,
                    }}
                    primary={item.display}
                  />
                </Box>
                {!item.active && (
                  <Chip
                    label="soon"
                    size="small"
                    sx={{
                      fontSize: "6px",
                      fontWeight: "600",
                      textTransform: "uppercase",
                      color: "#fff",
                      backgroundColor: "#D4024B",
                      py: 0,
                      px: "4px",
                      height: "16px",
                      pointerEvents: "none",
                      ".MuiChip-label": {
                        p: 0,
                        letterSpacing: "0.04em",
                      },
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: "11px",
            textTransform: "uppercase",
            color: "#969696",
            px: "10px",
          }}
        >
          General
        </Typography>
        <List>
          {generalItems.map((item) => (
            <ListItem
              sx={{ px: "10px", pb: "5px" }}
              key={item.section}
              disablePadding
            >
              <ListItemButton
                disabled={!item.active}
                component={Link}
                to={item.path}
                selected={
                  location.pathname !== "/" &&
                  item.path.split("/")[1] === location.pathname.split("/")[1]
                }
                sx={{
                  p: "10px",
                  borderRadius: "8px",
                  color: "#fff",
                  fill: "#fff",
                  "&.Mui-selected, &.Mui-selected .MuiListItemText-root,&.Mui-selected:hover":
                    {
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      fontWeight: "500",
                      color: "#102132",
                      fill: "#102132",
                    },
                  "&.Mui-selected .MuiTypography-root": {
                    fontWeight: "500",
                  },
                  "&.Mui-selected .MuiListItemText-root": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: "34px" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    ".MuiTypography-root": {
                      fontSi102132ze: "15px",
                    },
                    lineHeight: "1.5rem",
                    fontWeight: "400",
                  }}
                  primary={item.display}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ backgroundColor: "#102132!important" }}>
      <Box
        component="nav"
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          backgroundColor: "#102132!important",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={props.isOpen}
          onClose={props.toggler}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
            },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: sidebarWidth,
              backgroundColor: "#102132!important",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;
