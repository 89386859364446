import { Box, LinearProgress, Typography, Chip, Skeleton,Avatar } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import GridToolbarCustom from "../GridToolbar";


const TableSkeleton = () => {
  const loadingCols = [
    {
      field: "profilePic",
      headerName: "",
      id: "profilePic",
      isChecked: false,
      width: 30,
      height: 30,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <div className="account-preview-info--img">
            <Box className="relative w-10 h-10 select-none pointer-events-none ">
            <Avatar
                  src={''}
                  alt={''}
                  className="absolute top-0 left-0 w-full h-full z-10"
                />
            <Skeleton
                className="absolute top-0 left-0 w-full h-full "
                variant="circular"
              />
          </Box>
          </div>
          

          
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      id: "username",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    
    {
      field: "fullName",
      headerName: "Full Name",
      id: "fullName",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "followers",
      headerName: "Followers",
      id: "followers",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "followings",
      headerName: "Followings",
      id: "followings",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "posts",
      headerName: "Posts",
      id: "posts",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "userId",
      headerName: "User Id",
      id: "userId",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "bio",
      headerName: "Bio",
      id: "bio",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      id: "status",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },

    {
      field: "email",

      headerName: "Email",
      id: "email",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "mobile",

      headerName: "Mobile",
      id: "mobile",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "bioLink",

      headerName: "Link in Bio",
      id: "bioLink",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "category",

      headerName: "Category",
      id: "category",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "50px", borderRadius: "15px" }}
          />
        );
      },
    },
    {
      field: "isBusiness",

      headerName: "Is business",
      id: "isBusiness",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: () => {
        return (
          <Skeleton
            sx={{ width: "150px", height: "30px", borderRadius: "15px" }}
          />
        );
      },
    },
  ];
  const loadingRows = [
    {
      id: 1,
      profilePic: "",
      username:"",
      fullName: "",
      followers: "",
      followings: "",
      posts: "",
      userId: "",
      bio: "",
      status: "",
      email: "",
      mobile: "",
      bioLink: "",
      category: "",
      isBusiness: "",
    },
    {
        id: 2,
        fullName: "",
        followers: "",
        followings: "",
        posts: "",
        userId: "",
        bio: "",
        status: "",
        email: "",
        mobile: "",
        bioLink: "",
        category: "",
        isBusiness: "",
      },
      {
        id: 3,
        fullName: "",
        followers: "",
        followings: "",
        posts: "",
        userId: "",
        bio: "",
        status: "",
        email: "",
        mobile: "",
        bioLink: "",
        category: "",
        isBusiness: "",
      },
      {
        id: 4,
        fullName: "",
        followers: "",
        followings: "",
        posts: "",
        userId: "",
        bio: "",
        status: "",
        email: "",
        mobile: "",
        bioLink: "",
        category: "",
        isBusiness: "",
      },
      {
        id: 5,
        fullName: "",
        followers: "",
        followings: "",
        posts: "",
        userId: "",
        bio: "",
        status: "",
        email: "",
        mobile: "",
        bioLink: "",
        category: "",
        isBusiness: "",
      },
  ];
  return (
    <DataGrid
      sx={{
        gap: 2,
        pl: 2,
        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
        },
      }}
      columns={loadingCols}
      rows={loadingRows}
      slots={{
        toolbar: GridToolbarCustom,
      }}
    />
  );
};

export default TableSkeleton;
