const DollarIcon = ({ className, size = 17 }) => {
  return (
    <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 22 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.4023 0.375H2.5625C1.61328 0.375 0.875 1.14844 0.875 2.0625V14.4375C0.875 15.3867 1.61328 16.125 2.5625 16.125H19.4023C20.3516 16.125 21.125 15.3867 21.125 14.4375V2.0625C21.125 1.14844 20.3516 0.375 19.4023 0.375ZM2.77344 2.0625H19.1914C19.332 2.0625 19.4023 2.16797 19.4023 2.27344V3.75H2.5625V2.27344C2.5625 2.16797 2.63281 2.0625 2.77344 2.0625ZM19.1914 14.4375H2.77344C2.63281 14.4375 2.5625 14.3672 2.5625 14.2266V8.25H19.4023V14.2266C19.4023 14.3672 19.332 14.4375 19.1914 14.4375ZM7.625 10.9219C7.625 10.7109 7.41406 10.5 7.20312 10.5H4.67188C4.42578 10.5 4.25 10.7109 4.25 10.9219V12.3281C4.25 12.5742 4.42578 12.75 4.67188 12.75H7.20312C7.41406 12.75 7.625 12.5742 7.625 12.3281V10.9219ZM14.375 10.9219C14.375 10.7109 14.1641 10.5 13.9531 10.5H9.17188C8.92578 10.5 8.75 10.7109 8.75 10.9219V12.3281C8.75 12.5742 8.92578 12.75 9.17188 12.75H13.9531C14.1641 12.75 14.375 12.5742 14.375 12.3281V10.9219Z" />
  </svg>
  );
};

export { DollarIcon };
