import React, { PropTypes } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Package = (props) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Typography fontSize={18} fontWeight={400} >
          Price ${props.package.price}
        </Typography>
        <Typography fontSize={18} fontWeight={500}>
          <ArrowForwardIosIcon sx={{fontSize:"18px"}} />
          {` Get Credit ${props.package.credit_amount}`}$
        </Typography>
        <Typography color="primary" variant="subtitle2" fontWeight={600}>
          {props.package.description}
        </Typography>
      </Box>
    </div>
  );
};

export default Package;
