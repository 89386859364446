import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { EyeClose } from "../icons/EyeClose";
import { EyeOpen } from "../icons/EyeOpen";

//this component return a textfield from @mui/material contains a show/hide icon, which using for password input

const ShowHidePassword = ({
  className,
  name,
  label,
  value,
  onChange,
  onFocus,
  helperText,
  forwardedRef,
  placeholder,
  size,
  variant,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl className={className} variant="outlined">
      <TextField
        inputRef={forwardedRef}
        variant={variant}
        value={value}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        helperText={helperText}
        type={showPassword ? "text" : "password"}
        placeholder={placeholder}
        label={label}
        size={size}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={disabled}
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  ".MuiButtonBase-root.Mui-disabled": {
                    backgroundColor: "transparent!important",
                  },
                }}
              >
                {showPassword ? (
                  <EyeClose className="fill-gray-500" />
                ) : (
                  <EyeOpen className="fill-gray-500" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        required
      />
    </FormControl>
  );
};

export default ShowHidePassword;
