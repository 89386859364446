import React from "react";

const QuestionSquare = ({ className, size = 22 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.875 2.0625C15.875 1.14844 15.1016 0.375 14.1875 0.375H1.8125C0.863281 0.375 0.125 1.14844 0.125 2.0625V14.4375C0.125 15.3867 0.863281 16.125 1.8125 16.125H14.1875C15.1016 16.125 15.875 15.3867 15.875 14.4375V2.0625ZM14.1875 14.2266C14.1875 14.3672 14.082 14.4375 13.9766 14.4375H2.02344C1.88281 14.4375 1.8125 14.3672 1.8125 14.2266V2.27344C1.8125 2.16797 1.88281 2.0625 2.02344 2.0625H13.9766C14.082 2.0625 14.1875 2.16797 14.1875 2.27344V14.2266ZM11.7617 6.31641C11.7617 4.55859 9.89844 3.1875 8.17578 3.1875C6.52344 3.1875 5.46875 3.89062 4.66016 5.12109C4.51953 5.29688 4.55469 5.54297 4.73047 5.68359L5.71484 6.42188C5.89062 6.5625 6.17188 6.52734 6.3125 6.35156C6.83984 5.68359 7.22656 5.29688 8.03516 5.29688C8.66797 5.29688 9.44141 5.68359 9.44141 6.31641C9.44141 6.77344 9.05469 6.98438 8.42188 7.33594C7.71875 7.75781 6.76953 8.25 6.76953 9.51562V9.79688C6.76953 10.043 6.94531 10.2188 7.19141 10.2188H8.77344C9.01953 10.2188 9.19531 10.043 9.19531 9.79688V9.58594C9.19531 8.70703 11.7617 8.67188 11.7617 6.31641ZM9.47656 12.1875C9.47656 11.3789 8.80859 10.7109 8 10.7109C7.15625 10.7109 6.52344 11.3789 6.52344 12.1875C6.52344 13.0312 7.15625 13.6641 8 13.6641C8.80859 13.6641 9.47656 13.0312 9.47656 12.1875Z" />
    </svg>
  );
};

export { QuestionSquare };
