import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { resetPassword, updateUser, emailVerify } from "../../apis";import { UserInfoContext } from "../../App";
import ShowHidePassword from "../../components/inputs/ShowHidePassword";
import { useNavigate, Link } from "react-router-dom";import { notify } from "../../components/notify/toast";
import { CheckCircle, Warning } from "@mui/icons-material";
import { validate } from "../../components/validate";

const Settings = () => {
  const UserInfo = useContext(UserInfoContext);
  const { first_name, last_name, username, email, is_verified } = UserInfo.data;
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [serverErrors, setServerErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [fnRpt, setFnRpt] = useState(true);
  const [lnRpt, setLnRpt] = useState(true);
  const [isRpt, setIsRpt] = useState(true);
  const [info, setInfo] = useState({
    first_name: "",
    last_name: "",
  });
  const [infoLoading, setInfoLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const oldPassRef = useRef(null);
  const newPassRef = useRef(null);
  const newPassConfRef = useRef(null);

  const navigate = useNavigate();
  //The function accountChange handles an account change event, updates user information using the updateUser function, and handles the response accordingly.
  const accountChange = async (e) => {
    e.preventDefault();

    setInfoLoading(true);

    try {
      const response = await updateUser({
        first_name: firstNameRef.current.value,
        last_name: lastNameRef.current.value,
      });

      if (response.status === 200) {
        setIsRpt(true);
        navigate(0);
      } else if (response.status >= 400) {
        // setLoading(false);
        // setError("Server Error!");
        notify("An error occured!", "error");
      }
      // console.log(response);
      // console.log(response.status);
    } catch (e) {
      // console.log(e);
      notify("An error occured!", "error");
    } finally {
      // console.log("final");
      setInfoLoading(false);
    }
  };
  const verifyEmail = async(e) => {
    e.preventDefault();

    setInfoLoading(true);

    try {
      const response = await emailVerify();

      if (response.status === 200) {
        notify("An email containing a verify link has been sent to you!", "success")
      } else if (response.status >= 400) {
        // setLoading(false);
        // setError("Server Error!");
        notify("An error occured!", "error");
      }
      // console.log(response);
      // console.log(response.status);
    } catch (e) {
      // console.log(e);
      notify("An error occured!", "error");
    } finally {
      // console.log("final");
      setInfoLoading(false);
    }
  }
  const changeHandler = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setServerErrors({ ...serverErrors, [event.target.name]: undefined });
  };

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  //The function passwordChange handles a password change event, updates the password using the resetPassword function, and handles the response accordingly.
  const passwordChange = async (e) => {
    e.preventDefault();

    setPasswordLoading(true);

    try {
      const response = await resetPassword({
        old_password: oldPassRef.current.value,
        new_password: data.new_password,
        confirm_password: data.confirm_password,
      });
      // console.log(response);

      // console.log(response);
      if (response.status === 204) {
        // console.log(response);
        navigate(0);
      } else if (response.status >= 400) {
        // setLoading(false);
        // setError("Server Error!");
        notify("An Error Occured!", "error");
      }
      // console.log(response);
      // console.log(response.status);
    } catch (error) {
      // console.log(error);
      if (error.response.data !== undefined) {
        setServerErrors(error.response.data);
        // console.log(error.response.data);
        // console.log(errors);
      }
    } finally {
      setPasswordLoading(false);
    }
  };

  const changeInfoHandler = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });

    if (
      firstNameRef.current.value.trim() === "" ||
      lastNameRef.current.value.trim() === ""
    ) {
      setIsRpt(true);
    }
  };

  const passwordValidate = () => {
    if (
      oldPassRef.current.value.trim() === "" ||
      newPassRef.current.value.trim() === "" ||
      newPassConfRef.current.value.trim() === ""
    ) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  };

  useEffect(() => {
    document.title = "Settings - Binro";

    setErrors(validate(data, "change-password"));
  }, [data, touched, serverErrors]);

  // useEffect(() => {
  //   if (!is_verified) {
  //     setInterval(() => {
  //       navigate("/settings");
  //     }, 10000);
  //   }
  //   console.log(is_verified);
  // }, [is_verified]);

  useEffect(() => {
    setInfo({
      first_name: first_name,
      last_name: last_name,
    });
  }, [first_name, last_name]);
//rendering page elements
  return (
    <main className="grid grid-cols-2 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5">
      <Box
        component="section"
        className="account-section main-paper col-span-2"
      >
        <div className="main-paper-title mb-7">
          <Typography
            className="section-title-tpg"
            variant="subtitle1"
            fontWeight={600}
          >
            Account
          </Typography>
          <Typography variant="caption" fontSize={14}>
            Here you can change your account information
          </Typography>
        </div>
        <Box>
          <form onSubmit={accountChange} onChange={changeInfoHandler}>
            <Box className="grid grid-cols-2 gap-x-5 gap-y-8">
              {UserInfo.loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    className="col-span-2 sm:col-span-1 h-10 rounded-md"
                  />
                  <Skeleton
                    variant="rectangular"
                    className="col-span-2 sm:col-span-1 h-10 rounded-md"
                  />
                  <Skeleton
                    variant="rectangular"
                    className="col-span-2 sm:col-span-1 h-10 rounded-md"
                  />
                  <Skeleton
                    variant="rectangular"
                    className="col-span-2 sm:col-span-1 h-10 rounded-md"
                  />
                </>
              ) : (
                <>
                  <TextField
                    className="col-span-2 sm:col-span-1"
                    label="Username"
                    placeholder="elisabeth"
                    value={UserInfo.loading ? "Wait..." : username}
                    type="text"
                    required
                    size="small"
                    disabled
                  />
                  <TextField
                    className="col-span-2 sm:col-span-1"
                    label="Email Address"
                    placeholder="elisabeth123@example.com"
                    value={UserInfo.loading ? "Wait..." : email}
                    type="email"
                    required
                    size="small"
                    sx={{
                      ".MuiInputBase-root.MuiOutlinedInput-root": {
                        pr: 1,
                      },
                      input: {
                        pr: "14px",
                      },
                      "& fieldset": {
                        borderColor: is_verified
                          ? "#2e7d32!important"
                          : "#ed6c02!important",
                      },
                      label: {
                        color: is_verified
                          ? "#2e7d32!important"
                          : "#ed6c02!important",
                      },
                      ".MuiFormHelperText-root": {
                        color: "#ed6c02!important",
                      },
                    }}
                    InputProps={{
                      endAdornment: is_verified ? (
                        <CheckCircle color="success" />
                      ) : (
                        <Warning color="warning" />
                      ),
                    }}
                    helperText={
                      !is_verified && <Typography className="text-[#D4024B] font-semibold ml-3 cursor-pointer text-xs   underline underline-offset-2" onClick={verifyEmail} >Verify your email</Typography>                    }
                    disabled
                  />
                  <TextField
                    className="sm:col-span-1"
                    label="First Name"
                    placeholder="Mohammad"
                    name="first_name"
                    inputRef={firstNameRef}
                    onChange={(e) => {
                      if (e.target.value.trim() === first_name.trim()) {
                        setFnRpt(true);
                        if (!lnRpt) {
                          setIsRpt(false);
                        } else {
                          setIsRpt(true);
                        }
                      } else {
                        setFnRpt(false);
                        setIsRpt(false);
                      }
                    }}
                    value={UserInfo.loading ? "Wait..." : info.first_name}
                    type="text"
                    required
                    size="small"
                  />
                  <TextField
                    className="sm:col-span-1"
                    label="Last Name"
                    placeholder="Elisabeth"
                    name="last_name"
                    inputRef={lastNameRef}
                    onChange={(e) => {
                      if (e.target.value.trim() === last_name.trim()) {
                        setLnRpt(true);
                        if (!fnRpt) {
                          setIsRpt(false);
                        } else {
                          setIsRpt(true);
                        }
                      } else {
                        setLnRpt(false);
                        setIsRpt(false);
                      }
                    }}
                    value={UserInfo.loading ? "Wait..." : info.last_name}
                    type="text"
                    required
                    size="small"
                  />
                </>
              )}
              <Box className="col-span-2 flex justify-start">
                <Button
                  type="submit"
                  variant="contained"
                  className="w-fit normal-case ml-auto bg-[#D4024B]"
                  startIcon={
                    infoLoading && (
                      <CircularProgress
                        sx={{
                          circle: {
                            stroke: "#fff",
                          },
                        }}
                        size={22}
                      />
                    )
                  }
                  disabled={isRpt}
                >
                  Change Information
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>

      <Box
        component="section"
        className="password-section main-paper min-h-[300px] col-span-2"
      >
        <div className="main-paper-title mb-7">
          <Typography
            className="section-title-tpg"
            variant="subtitle1"
            fontWeight={600}
          >
            Security
          </Typography>
          <Typography variant="caption" fontSize={14}>
            Here you can change your account password
          </Typography>
        </div>
        <Box>
          <form onSubmit={passwordChange} onChange={passwordValidate}>
            <Box className="grid grid-cols-2 gap-x-5 gap-y-8">
              <ShowHidePassword
                name="old_password"
                onChange={changeHandler}
                onFocus={focusHandler}
                className="col-span-2"
                label="Old Password"
                placeholder="●●●●●●●●●●●"
                size="small"
                forwardedRef={oldPassRef}
                helperText={
                  (errors.old_password &&
                    touched.old_password &&
                    errors.old_password) ||
                  (serverErrors.old_password !== undefined &&
                    serverErrors.old_password)
                }
              />
              <ShowHidePassword
                name="new_password"
                onChange={changeHandler}
                onFocus={focusHandler}
                value={data.new_password}
                className="col-span-2"
                label="New Password"
                placeholder="●●●●●●●●●●●"
                size="small"
                forwardedRef={newPassRef}
                helperText={
                  (errors.new_password &&
                    touched.new_password &&
                    errors.new_password) ||
                  (serverErrors.new_password !== undefined &&
                    serverErrors.new_password)
                }
              />
              <ShowHidePassword
                name="confirm_password"
                onChange={changeHandler}
                onFocus={focusHandler}
                value={data.confirm_password}
                className="col-span-2"
                label="New Password Confirmation"
                placeholder="●●●●●●●●●●●"
                size="small"
                forwardedRef={newPassConfRef}
                helperText={
                  (errors.confirm_password &&
                    touched.confirm_password &&
                    errors.confirm_password) ||
                  (serverErrors.confirm_password !== undefined &&
                    serverErrors.confirm_password)
                }
              />
              <Box className="col-span-2">
                <Box className="flex justify-start items-center gap-4">
                  <Button
                    type="submit"
                    variant="contained"
                    className="w-fit normal-case ml-auto bg-[#D4024B]"
                    startIcon={
                      passwordLoading && (
                        <CircularProgress
                          sx={{
                            circle: {
                              stroke: "#fff",
                            },
                          }}
                          size={22}
                        />
                      )
                    }
                    disabled={isEmpty}
                  >
                    Change Password
                  </Button>
                  {/* <Button
                    variant="contained"
                    className="w-fit normal-case bg-[#D4024B]"
                    endIcon={<Help />}
                  >
                    <Link to="https://binro.io/support">Support</Link>
                  </Button> */}
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </main>
  );
};

export default Settings;
