export const validate = (data, type) => {
  const min = 3;
  const max = 25;
  const isRequired = (value) => (value.trim() ? false : true);
  const isBetween = (length, min, max) =>
    length < min || length > max ? false : true;

  const isEmailValid = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  const isPasswordSecure = (password) => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
    // const re = new RegExp(
    //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    // );
    return re.test(password);
  };

  const errors = {};

  if (type === "sign-in") {
    //   Username Validation
    if (isRequired(data.username)) {
      errors.username = "Username is required";
    } else if (!isBetween(data.username.length, min, max)) {
      errors.username = `Username must be between ${min} and ${max} characters`;
    } else {
      delete errors.username;
    }

    //   Password Validation
    if (isRequired(data.password)) {
      errors.password = "Password is required";
    } else {
      delete errors.password;
    }
  }

  if (type === "sign-up") {
    //   First Name & Last Name Validation
    // if (isRequired(data.first_name)) {
    //   errors.first_name = "First Name is required";
    // } else {
    //   delete errors.first_name;
    // }
    // if (isRequired(data.last_name)) {
    //   errors.last_name = "Last Name is required";
    // } else {
    //   delete errors.last_name;
    // }

    //   Username Validation
    if (isRequired(data.username)) {
      errors.username = "Username is required";
    } else if (!isBetween(data.username.length, min, max)) {
      errors.username = `Username must be between ${min} and ${max} characters`;
    } else {
      delete errors.username;
    }

    //   Email Validation
    if (isRequired(data.email)) {
      errors.email = "Email is required";
    } else if (!isEmailValid(data.email)) {
      errors.email = "Email address is invalid";
    } else {
      delete errors.email;
    }

    if (isRequired(data.password)) {
      errors.password = "Password is required";
    } else if (!isPasswordSecure(data.password)) {
      errors.password =
        "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number";
    } else {
      delete errors.password;
    }

    //   Confirm Password Validation
    if (isRequired(data.confirm_password)) {
      errors.confirm_password = "Confirm your Password!";
    } else if (data.confirm_password !== data.password) {
      errors.confirm_password = "Passwords are not the same";
    } else {
      delete errors.confirm_password;
    }

    //   Phone Number Validation
    // if (isRequired(data.phone_number)) {
    //   errors.phone_number = "Phone Number is required";
    // } else if (!data.phone_number.match(/^\d{10}$/)) {
    //   errors.phone_number = "Phone Number is invalid";
    // } else {
    //   delete errors.phone_number;
    // }

    //   Is Accepted Validation
    // if (data.is_accepted) {
    //   delete errors.is_accepted;
    // } else {
    //   errors.is_accepted = "Please Accept our regulations!";
    // }
  }

  if (type === "change-password") {
    if (isRequired(data.new_password)) {
      errors.new_password = "Password is required";
    } else if (!isPasswordSecure(data.new_password)) {
      errors.new_password =
        "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number";
    } else {
      delete errors.new_password;
    }

    //   Confirm Password Validation
    if (isRequired(data.confirm_password)) {
      errors.confirm_password = "Confirm your Password!";
    } else if (data.confirm_password !== data.password) {
      errors.confirm_password = "Passwords are not the same";
    } else {
      delete errors.confirm_password;
    }
  }

  if (type === "forgot-password") {
    //   Email Validation
    if (isRequired(data.email)) {
      errors.email = "Email is required";
    } else if (!isEmailValid(data.email)) {
      errors.email = "Email address is invalid";
    } else {
      delete errors.email;
    }
  }

  return errors;
};
