import React from "react";

const Home = ({ className, size = 22 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 4.29297L3.30078 11.0781C3.23047 11.1484 3.16016 11.1484 3.125 11.2188V17.6875C3.125 18.0039 3.37109 18.25 3.6875 18.25H8.1875C8.46875 18.25 8.75 18.0039 8.75 17.6875V13.1875C8.75 12.9062 8.99609 12.625 9.3125 12.625H12.6875C12.9688 12.625 13.25 12.9062 13.25 13.1875V17.6875C13.25 18.0039 13.4961 18.25 13.8125 18.25H18.3125C18.5938 18.25 18.875 18.0039 18.875 17.6875V11.2188C18.8047 11.1484 18.7344 11.1484 18.6641 11.0781L11 4.29297ZM20.9141 8.58203L11.9141 0.636719C11.668 0.390625 11.3516 0.25 11 0.25C10.6133 0.25 10.2969 0.390625 10.0508 0.636719L1.05078 8.58203C0.945312 8.6875 0.839844 8.82812 0.839844 9.00391C0.839844 9.14453 0.910156 9.28516 1.01562 9.35547L1.75391 10.1992C1.85938 10.3047 2 10.4102 2.17578 10.4102C2.31641 10.4102 2.45703 10.3398 2.5625 10.2344L10.6133 3.13281C10.7188 3.0625 10.8242 2.99219 10.9648 2.99219C11.1406 2.99219 11.2461 3.0625 11.3516 3.13281L19.4023 10.2344C19.5078 10.3398 19.6484 10.375 19.7891 10.375C19.9648 10.375 20.1055 10.3047 20.2109 10.1992L20.9492 9.35547C21.0547 9.28516 21.0898 9.14453 21.0898 9.00391C21.0898 8.82812 21.0195 8.6875 20.9141 8.58203Z" />
    </svg>
  );
};

export { Home };
