import React from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";

const ReportListSkeleton = () => {
  return (
    <Box className="analysis-box flex gap-4 py-4">
      <Box className="w-full flex items-center justify-between">
        <Skeleton width={100} variant="text" />
        <Skeleton
          className="rounded-lg"
          width={120}
          height={30}
          variant="rectangular"
        />
      </Box>
    </Box>
  );
};

export default ReportListSkeleton;
