import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./header/Navbar";

const Drawer = ({ sidebarWidth }) => {
  const [open, setOpen] = React.useState(false);
  const { pathname } = useLocation();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(false); // Close the navigation panel
  }, [pathname]);

  return (
    <Box className="flex bg-[#102132]" sx={{ backgroundColor: "#102132" }}>
      <Sidebar
        sidebarWidth={sidebarWidth}
        toggler={handleDrawerToggle}
        isOpen={open}
      />
      <Navbar sidebarWidth={sidebarWidth} toggler={handleDrawerToggle} />
    </Box>
  );
};

export default Drawer;
