import { WifiOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);
  const navigate = useNavigate();

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
    setTimeout(() => {
      navigate(0);
    }, 3000);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <Box
        className="fixed top-16 right-0 bg-[#102132]/50 text-white p-2"
        sx={{
          width: { xs: "100%", lg: `calc(100% - ${props.sidebarWidth}px)` },
        }}
      >
        <h1 className="text-sm flex justify-center items-center gap-3">
          Your are Offline! <WifiOff fontSize="small" />
        </h1>
      </Box>
    );
  }
};

export default NoInternetConnection;
