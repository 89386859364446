import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Add,
  Cancel,
  ChevronRight,
  Info,
  Instagram,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  LinearProgress,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
// import avatar_1 from "../../assets/images/avatar/1.png";
import FollowerListFollower from "../../components/previews/FollowerListFollower";
// import check from "../../assets/images/check.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  features as userFeatures,
  report,
  calculate,
  approveCredit,
  fetchFollowersInfo
} from "../../apis";
import { notify } from "../../components/notify/toast";
import { UserInfoContext } from "../../App";
import { DollarIcon } from "../../components/icons/Dollar";

const FollowerList = ({ sidebarWidth }) => {
  const [feats, setFeats] = useState([]);
  const [features, setFeatures] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [featureLoading, setFeatureLoading] = useState(true);
  const [priceLoading, setPriceLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const [instaUsername, setInstaUsername] = useState("");
  const [instaError, setInstaError] = useState("");

  const [reportLoading, setReportLoading] = useState(false);

  const [pages, setPages] = useState([]);

  const [pagesPrice, setPagesPrice] = useState(0);
  const [modalText, setModalText] = useState("");
  const [reportId, setReportId] = useState(0);

  const UserInfo = useContext(UserInfoContext);
  const { data, setUser } = UserInfo;
  const { credit } = data;

  const navigate = useNavigate();

  const instaUsernameRef = useRef(null);
  const submitButtonRef = useRef(null);

  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const token = getToken();
  // This function fetches user features data asynchronously.
  const fetchFeatures = async () => {
    const response = await userFeatures();

    // console.log(response);
    if (response.status === 200) {
      setFeats(response.data);
      setFeatures([response.data[0]]);
      setFeatureLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  // This function is responsible for creating a report asynchronously.

  const makeReport = async () => {
    setReportLoading(true);
    
    try {
      const response = await report({
        name: Array.from(pages.values(), (user) => user.username).join(", "),
        userid_list: Array.from(pages.values(), (user) => user.id).join(","),
        type: "FL",
      });

      if (response.status === 201) {
        setReportId(response.data.id);
      }
    } catch (error) {
      if (error.response.status >= 400) {
        setInstaError("An error occured!");
      }
    } finally {
      setReportLoading(false);
    }
  };
  useEffect(() => {
    const approval = async () => {
      try {
        const response2 = await approveCredit(reportId);

        if (response2.status === 200) {
          const updatedUserInfo = {
            ...data,
            credit: parseFloat((credit - pagesPrice).toFixed(6)),
          };
          setUser(updatedUserInfo);
          fetchFollowersInfo(reportId);

          navigate("/result", {
            state: {
              status: response2.status,
              follower: pages[0].edge_followed_by.count,
              type: "FL"
            },
          });
          localStorage.removeItem("Fpages");
          setReportLoading(false)

        }
      } catch (err) {
        if (err.response2.status === 400) {
          notify("Credit is not enough.", "error");
        }
      }
    };
    const resultMaker = async ()=>{
      try{
        const response = await fetchFollowersInfo(reportId);
        if(response.status===200){
         
        }
      }
      catch(error){
        notify("Something went wrong.", "error")
      }
    }
    if (reportId != 0) {
      approval();
      // resultMaker()
    }
  }, [reportId]);
  useEffect(() => {
    const pages = JSON.parse(localStorage.getItem("Fpages"));
    if (pages) {
      setPages(pages);
    }

    fetchFeatures();

    // console.log(pages);

    document.title = "Followers List Information - Binro";
  }, []);

  useEffect(() => {
    localStorage.setItem("Fpages", JSON.stringify(pages));
  }, [pages]);

  // This function is a useCallback hook that handles the deletion of a user by their id.

  const deleteHandler = useCallback(
    (id) => {
      const newPages = pages.filter((user) => user.id !== id);

      localStorage.setItem("Fpages", JSON.stringify(pages));
      setPages(newPages);

      // Delete last feature if pages length less than 2
      //   const lastFeature = feats.at(-1);

      //   for (let i = 0; i < features.length; i++) {
      //     if (features[i].id === lastFeature.id) {
      //       if (pages.length <= 2) {
      //         const lastFeatureIndex = features.findIndex((obj) => obj.id === id);
      //         features.splice(lastFeatureIndex, 1);
      //       }
      //       break;
      //     }
      //   }
    },

    [pages, feats]
  );

  // This function is responsible for adding a user to the 'pages' array.
  const addUser = (response) => {
    if (!pages.some((el) => el.id === response.user.id)) {
      setPages((oldArray) => [...oldArray, response.user]);
      setUserLoading(false);
      //   notify("Page added successfully", "success");
      setInstaUsername("");
      // console.log(response);
    } else {
      toast.error("This page has already been added!");

      setUserLoading(false);
    }
  };
  // This function calculates the total price based on the number of pages and features.
  useEffect(() => {
    const cal = async () => {
      setPriceLoading(true);
      try {
        const q = Array.from(pages.values(), (user) => user.id).join(",");
        const response = await calculate({
          userid_list: q,
          type: "FL",
        });

        if (response.status === 200) {
          setPagesPrice(parseFloat(response.data.message).toFixed(3));
          setPriceLoading(false);

          // console.log(response);
          //   navigate("payment", {
          //     state: {
          //       pages: pages,
          //       // features: features,
          //       totalPrice: calcPrice(pages),
          //       reportId: response.data.id,
          //       credit: credit,
          //     },
          //   });
        }
      } catch (error) {
        if (error.response.status >= 400) {
          notify("Type is invalid.", "error");
          setPriceLoading(false);
        }
      }
    };
    if (pages.length > 0) {
      cal();
    }
    if (pages.length === 0) {
      setPagesPrice(0);
    }
  }, [pages]);
  // This function handles the form submission event.

  const submitHandler = async (event) => {
    event.preventDefault();
    if (
      !pages.some((el) => el.username === instaUsername) &&
      instaUsername.trim() !== ""
    ) {
      if (pages.length < 3) {
        setUserLoading(true);
        setInstaError(undefined);
        // Make a GET request to fetch data from the Instagram search API.

        await axios({
          method: "GET",
          url: `https://api.binro.io/instagram_search/?username=${instaUsername}`,
          headers: {
            Authorization: `TOKEN ${token}`,
          },
          data: "",
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.data.user.is_private) {
                notify("You can only add a public page.", "error")
              } else {
                if (
                  response.data.data.user.edge_followed_by.count <
                  process.env.REACT_APP_FOLLOWERS_INFO_LIMIT
                ) {
                  addUser(response.data.data);
                } else {
                  setInstaError(
                    "Maximum follower is 100K, for more followers please contact us."
                  );
                }
              }
            }
          })

          .catch((error) => {
            if (error instanceof TypeError) {
              setInstaError("Username is incorrect or does not exist");
            } else {
              setInstaError(error.message);
            }
          })
          .finally(() => {
            setUserLoading(false);
          });
      } else {
        setInstaError("You can only add 1 pages");
      }

      // if (pages.length < 3) {
      //   setUserLoading(true);
      //   setInstaError(undefined);

      // await axios
      //   .request(options)
      //   .then((response) => {
      //     // console.log(response);
      //     if (response.status === 200) {
      //       if (response.data.data.user.edge_followed_by.count < 1000000) {
      //         addUser(response);
      //       } else {
      //         setUserLoading(false);
      //         setInstaError("Maximum follower is 1M");
      //       }
      //     }
      //   })
      //   .catch((error) => {
      //     setUserLoading(false);
      //     setInstaError(error.response.data.message);
      //     console.log(error);

      //     if (error.response.status >= 500) {
      //       setInstaError(`Server Error: ${error.response.data.message}`);
      //     }
      //     console.log(error);
      //   })
      //   .finally(() => {
      //     setUserLoading(false);
      //   });

      //   await axios
      //     .post(
      //       "https://rocketapi-for-instagram.p.rapidapi.com/instagram/user/get_info",
      //       { username: instaUsername },
      //       {
      //         headers: {
      //           "content-type": "application/json",
      //           "X-RapidAPI-Key":
      //             "cd9953a562mshcf86963ae97af0dp1c2bfajsnc6814e815396",
      //           "X-RapidAPI-Host": "rocketapi-for-instagram.p.rapidapi.com",
      //         },
      //       }
      //     )
      //     .then((response) => {
      //       if (response.status === 200) {
      //         if (
      //           response.data.response.body.data.user.edge_followed_by.count <
      //           1000000
      //         ) {
      //           addUser(response);
      //         } else {
      //           setInstaError("Maximum follower is 1M");
      //         }
      //       }
      //       console.log(response.data.response.body.data.user);
      //       console.log(response);
      //     })
      //     .catch((error) => {
      //       if (error instanceof TypeError) {
      //         setInstaError("Username is incorrect or does not exist");
      //       } else {
      //         setInstaError(error.message);
      //       }
      //     })
      //     .finally(() => {
      //       setUserLoading(false);
      //     });
      // } else {
      //   setInstaError("You can only add 3 pages");
      // }
    } else if (instaUsername.trim() === "") {
      setInstaError("Please enter the page username first");
    } else {
      setInstaError("This page has already been added!");
    }
  };
  const chargeHandler = () => {
    // history.push("/packages");

    navigate("/packages", {
      state: {
        pages: pages,
        totalPrice: pagesPrice,
        credit: credit,
        type: "FL",
      },
    });
  };
  //rendering page elements
  return (
    <Box className="flex">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "20px" }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {<DollarIcon size={20}></DollarIcon>}
          Your credit: {credit}$
        </DialogTitle>
        <DialogContent sx={{ color: "red" }}>
          <DialogContentText id="alert-dialog-description" component={"span"}>
            {credit > pagesPrice ? (
              <Typography>
                Do you want to deduct {pagesPrice}$ from your credit?
              </Typography>
            ) : (
              <Typography>
                You need to increase {(parseFloat(pagesPrice - credit).toFixed(3))     }$ credit to complete
                the purchase.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        {credit > pagesPrice ? (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              "& .MuiButton-contained": {
                backgroundColor: "#D20D4B",
              },
            }}
          >
            <Button onClick={handleClose} variant="outlined" sx={{ px: 5 }}>
              No
            </Button>
            <Button onClick={makeReport} variant="contained" sx={{ px: 5 }} endIcon={
                  reportLoading ? (
                    <CircularProgress
                      sx={{
                        circle: {
                          stroke: "#fff",
                        },
                      }}
                      size={22}
                    />
                  ) : (
                    <ChevronRight />
                  )
                }>
              Yes
            </Button>
          </DialogActions>
        ) : (
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              "& .MuiButton-contained": {
                backgroundColor: "#D20D4B",
              },
            }}
          >
            <Button onClick={handleClose} variant="outlined" sx={{ px: 5 }}>
              Cancle
            </Button>
            <Button
              onClick={chargeHandler}
              variant="contained"
              sx={{
                px: 5,
                "& .MuiButton-contained": {
                  backgroundColor: "#D20D4B",
                },
              }}
              className="opacity-100"
            >
              Charge Now
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <main className="grid grid-cols-1 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5 pb-[100px]">
        <Box
          className="fixed right-0"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sidebarWidth}px)`,
            },
            top: { xs: "48px", sm: "64px" },
            "& .MuiLinearProgress-root": {
              backgroundColor: "transparent",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#D4024B",
            },
            zIndex: 10,
          }}
        >
          <LinearProgress variant="determinate" value={100 / 3} />
        </Box>

        <Box
          component="section"
          className="add-section main-paper p-4 flex flex-col"
        >
          <Typography fontSize={20} fontWeight={600} color={"black"}>
            Choose a page to Get Info list of followers.
          </Typography>
          <br></br>
          <div className="add-section-top">
            <form onSubmit={submitHandler}>
              <div className="grid grid-cols-1 gap-x-5 gap-y-2 sm:flex sm:items-stretch">
                <div className="accounts-ids w-full">
                  <div className="chips-wrapper w-full">
                    {/* <TextField
                      inputRef={instaUsernameRef}
                      className="w-full"
                      placeholder="elisabet"
                      label="Instagram Username"
                      sx={{
                        ".MuiInputBase-root.MuiFilledInput-root": {
                          borderRadius: "1rem 1rem 0 0!important",
                        },
                      }}
                      required
                    /> */}
                    <Box>
                      <TextField
                        className="w-full h-12"
                        variant="outlined"
                        placeholder="Your competitor's Instagram page"
                        type="text"
                        value={instaUsername}
                        onInput={(e) => {
                          e.target.value.indexOf("@") === 0
                            ? setInstaUsername(e.target.value.substring(1))
                            : setInstaUsername(e.target.value);
                        }}
                        inputRef={instaUsernameRef}
                        InputProps={{
                          startAdornment: (
                            <Avatar className="w-9 h-9 cursor-default">
                              <Instagram />
                            </Avatar>
                          ),
                          endAdornment: instaUsername !== "" && (
                            <IconButton
                              onClick={() => setInstaUsername("")}
                              className="text-gray-400 fill-black p-0"
                              disabled={userLoading}
                            >
                              <Cancel />
                            </IconButton>
                          ),
                        }}
                        inputProps={{
                          className:
                            "px-[14px] py-0 h-full bg-[transparent!important] text-[15px]",
                        }}
                        sx={{
                          ".MuiInputBase-root": {
                            height: "48px",
                            borderRadius: "10px",
                            pl: "6px",
                            border: "none",
                            backgroundColor: "#f2f2f2",
                          },
                        }}
                        autoFocus
                        required
                        disabled={
                          userLoading ||
                          pages.length == 1 ||
                          (credit > 0 && pages.length === credit)
                            ? true
                            : false
                        }
                      />
                    </Box>
                    {instaError && (
                      <Box className="py-2">
                        <FormHelperText>{instaError}</FormHelperText>
                      </Box>
                    )}
                  </div>
                </div>

                <div className="add-button">
                  <Button
                    ref={submitButtonRef}
                    type="submit"
                    variant="contained"
                    className="w-full bg-[#D4024B] capitalize rounded-[10px] sm:px-5 sm:h-12 text-lg shadow-none sm:w-fit"
                    startIcon={
                      userLoading ? (
                        <CircularProgress
                          sx={{
                            circle: {
                              stroke: "#fff",
                            },
                          }}
                          size={22}
                        />
                      ) : (
                        <Add />
                      )
                    }
                    size="large"
                    disabled={
                      userLoading ||
                      pages.length == 1 ||
                      (credit > 0 && pages.length === credit)
                        ? true
                        : false
                    }
                  >
                    Add
                  </Button>
                </div>
              </div>
              {pages.length == 1 ? (
                <Box className="py-2">
                  <FormHelperText className="flex flex-wrap items-center gap-1 text-orange-500 px-1">
                    <Info className="text-base" />
                    You can only add one page.
                  </FormHelperText>
                </Box>
              ) : (
                ""
              )}
            </form>
          </div>
          <div className=" mt-5">
            <Box className=" gap-4">
              {pages.length ? (
                pages.map((page) => (
                  <FollowerListFollower
                    key={page.id}
                    page={page}
                    onDelete={() => deleteHandler(page.id)}
                  />
                  // <img src="data:image/png;base64," alt="" />
                ))
              ) : (
                <h2>No page has been added yet.</h2>
              )}
            </Box>
          </div>
        </Box>

        <Box
          component="section"
          className="next-level-section z-30 fixed bottom-0 right-0 bg-white p-4 shadow-[0_4px_20px_rgba(0,0,0,0.1)]"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sidebarWidth}px)`,
            },
          }}
        >
          <div className="flex items-center justify-between">
            <div className="final-cost flex items-center gap-4">
              <Typography
                fontSize={26}
                fontWeight={600}
                className="flex items-center"
              >
                {priceLoading ? (
                  <CircularProgress
                    sx={{
                      circle: {
                        stroke: "#102132",
                      },
                    }}
                    size={26}
                  />
                ) : (
                  `${pagesPrice} $`
                )}
              </Typography>
            </div>
            <div className="next-button">
              {/* <Link
                  className="flex"
                  to="payment"
                  state={{
                    pages: pages,
                    features: features,
                    totalPrice: calcPrice(pages, features),
                  }}
                > */}
              <Button
                disabled={
                  !pages.length ||
                  (reportLoading && true) ||
                  (credit > 0 && pages.length > credit)
                }
                variant="contained"
                className="bg-[#D4024B] capitalize rounded-[10px] px-5 h-12 text-lg shadow-none"
                
                onClick={handleOpen}
                size="large"
              >
                Next
              </Button>
              {/* </Link> */}
            </div>
          </div>
        </Box>
      </main>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default FollowerList;
