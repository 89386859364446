import React from "react";
import {
  Avatar,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/system";

const followerListFollower = ({ page, onDelete, result = false }) => {
  const roundNumber = (number, decPlaces) => {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    var abbrev = ["K", "M", "B", "T"];

    // Go through the array backwards, so we do the largest first
    for (var i = abbrev.length - 1; i >= 0; i--) {
      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);

      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round((number * decPlaces) / size) / decPlaces;

        // Handle special case where we round up to the next abbreviation
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }

        // Add the letter for the abbreviation
        number += abbrev[i];

        // We are done... stop
        break;
      }
    }

    return number;
  };

  // const viewImage = () => {
  //   const e = imgRef.src.split("/");
  //   var t = "";
  //   for (let c = 0; c < e.length; c++)
  //     2 === c
  //       ? (t +=
  //           e[c].replaceAll("-", "--").replaceAll(".", "-") +
  //           atob("LnRyYW5zbGF0ZS5nb29n") +
  //           "/")
  //       : c !== e.length - 1
  //       ? (t += e[c] + "/")
  //       : (t += e[c]);
  //   return (imgRef.src = encodeURI(t)), encodeURI(t);
  // };

  const viewImage = (url) => {
    const e = url.split("/");
    let t = "";

    for (let c = 0; c < e.length; c++)
      2 === c
        ? (t +=
            e[c].replaceAll("-", "--").replaceAll(".", "-") +
            atob("LnRyYW5zbGF0ZS5nb29n") +
            "/")
        : c !== e.length - 1
        ? (t += e[c] + "/")
        : (t += e[c]);
    return (url = encodeURI(t)), encodeURI(t);
  };

  return (
    <div className="pr-0  pt-0 rounded-2xl bg-[#F7F7F7] flex flex-col items-start justify-start">
      <div className="account-preview max-w-full w-full flex flex-col sm:flex-row relative">
        {!result ? (
          <Tooltip
            title="Remove Page"
            placement="right-start"
            className="m-[8px] ml-4"
          >
            <IconButton
              onClick={onDelete}
              className="absolute hover:bg-inherit p-1 right-1 top-1"
            >
              <Delete className="text-md w-5 h-5 text-gray-400" />
            </IconButton>
          </Tooltip>
        ) : (
          ""
        )}
        <div className="flex flex-col sm:flex-row justify-start space-x-5 align-center min-h-full w-full  m-[30px] pr-[30px]">
          <div className="account-preview-info mr-4">
            <div className="flex gap-4 justify-center ">
              <div className="account-preview-info--img">
                <Box className="relative w-16 h-16 select-none pointer-events-none">
                  <Avatar
                    src={viewImage(page.profile_pic_url)}
                    alt={`${page.username}'s profile`}
                    className="absolute top-0 left-0 w-full h-full z-10"
                  />
                  <Skeleton
                    className="absolute top-0 left-0 w-full h-full"
                    variant="circular"
                  />
                </Box>
              </div>
              <div className="flex flex-col gap-2.5">
                <Typography variant="h6" fontSize={16} fontWeight={500}>
                  {page.full_name}
                </Typography>
                <Typography fontSize={14} color="#999999">
                  {page.username}
                </Typography>
              </div>
            </div>
          </div>
          <div className="account-preview-statistics  ">
            <div className="flex justify-center gap-3 xl:gap-5 border-t sm:border-l sm:border-t-0 sm:pl-2 pt-3 mt-3 sm:mt-0 sm:pt-0 w-[80%] sm:w-auto">
              <div className="statistics-box posts-amount flex flex-col gap-1 items-center w-10 h-14 xl:w-[70px] xl:h-[60px]">
                <h5 className="font-medium text-lg lg:text-base xl:text-lg leading-6">
                  {page.edge_owner_to_timeline_media.count}
                </h5>
                <h6 className="font-medium text-xs leading-6">Posts</h6>
              </div>
              <div className="statistics-box posts-amount flex flex-col gap-1 items-center w-14 h-14 xl:w-[70px] xl:h-[60px]">
                <h5 className="font-medium text-lg lg:text-base xl:text-lg leading-6">
                  {roundNumber(page.edge_followed_by.count, 2)}
                </h5>
                <h6 className="font-medium text-xs leading-6">Followers</h6>
              </div>
              <div className="statistics-box posts-amount flex flex-col gap-1 items-center w-14 h-14 xl:w-[70px] xl:h-[60px]">
                <h5 className="font-medium text-lg lg:text-base xl:text-lg leading-6">
                  {page.edge_follow.count}
                </h5>
                <h6 className="font-medium text-xs leading-6">Followings</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div >
        {page.biography === "-" ? (
          ""
        ) : (
           <Box className="flex">
             <Typography fontSize={12} className="sm:ml-[5rem] ml-20 pb-5 ">
            {page.biography}
          </Typography>
           </Box>
        )}
      </div>
    </div>
  );
};

export default followerListFollower;
