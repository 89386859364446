import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Box,
  LinearProgress,
  Typography,
  Chip,
  Avatar,
  Skeleton,
  IconButton,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Modal,
  Paper,
} from "@mui/material";
import CustomModal from "../../components/CustomModal/CustomModal";
import { instagramUser, fetchFollowersInfo } from "../../apis";
import { notify } from "../../components/notify/toast";
import FollowerListFollower from "../../components/previews/FollowerListFollower";
import Profile from "../../components/skeleton/ProfileSkeleton";

import { useLocation } from "react-router-dom";
// import data from "../../response-ac.json";
import { DataGrid } from "@mui/x-data-grid";
import GridToolbarCustom from "../../components/GridToolbar";
import TableSkeleton from "../../components/skeleton/TableSkeleton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";

const FollowersInfo = ({ sidebarWidth }) => {
  const [data, setData] = useState();
  const [page, setPage] = useState();
  const [pageLoading, setPageLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const location = useLocation();
  const { report_id, username, userId } = location.state;
  // const [counter, setCounter] = useState(1)
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalcontent] = useState({});
//remove this after setting pagination in back
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const token = getToken();


  const fetchFollowers = async () => {
   try{ const response = await axios.get(
      `followers-list/${report_id}/`,
      {
        baseURL: process.env.REACT_APP_API_URL,
        timeout:180000,
        headers: {
          Authorization: `TOKEN ${token}`,
        },
      }
    )
     if (response.status === 200) {
        setData(response.data);
        setTableLoading(false);
        // setCount(response.data[0].price);
      }
    }catch(error){
      notify(error.message, "error");

    }
    // axios
    //   .request({
    //     baseURL: process.env.REACT_APP_API_URL,
    //     timeout: 180000,
    //     method: "GET",
    //     url: `followers-list/${report_id}/`,
    //   })
    //   .then((response) => {
    //     if (response.status === 200) {
    //       setData(response.data);
    //       setTableLoading(false);
    //       // setCount(response.data[0].price);
    //     }
    //   })
    //   .catch((error) => {
    //     notify(error.message, "error");
    //   });

    // try {
    //   const response = await fetchFollowersInfo(report_id);

    //   if (response.status === 200) {
    //     setData(response.data);
    //     setTableLoading(false);
    //     // setCount(response.data[0].price);
    //   }
    // } catch (error) {
    //   notify(error.message, "error");
    // }
  };

  const viewImage = (url) => {
    const e = url.split("/");
    let t = "";

    for (let c = 0; c < e.length; c++)
      2 === c
        ? (t +=
            e[c].replaceAll("-", "--").replaceAll(".", "-") +
            atob("LnRyYW5zbGF0ZS5nb29n") +
            "/")
        : c !== e.length - 1
        ? (t += e[c] + "/")
        : (t += e[c]);
    return (url = encodeURI(t)), encodeURI(t);
  };
  const handleModalOpen = (e) => {
    const content = {
      page: {
        username: e.row.username,
        profile_pic_url: e.row.profilePic,
        full_name: e.row.fullName,
        edge_owner_to_timeline_media: {
          count: e.row.posts,
        },
        edge_followed_by: {
          count: e.row.followers,
        },
        edge_follow: {
          count: e.row.followings,
        },
        biography: e.row.bio,
      },
      info: e.row,
    };
    setModalcontent(content);
    setOpen(true);
  };

  const checkboxes = [
    {
      field: "profilePic",
      headerName: "",
      id: "profilePic",
      isChecked: false,
      width: 30,
      headerAlign: "center",
      align: "center",
      hideable: false,
      renderCell: (params) => {
        return (
          <div>
            <Box className="relative w-16 h-16 select-none pointer-events-none bg-[transparent]">
              <Avatar
                src={viewImage(params.value)}
                className="absolute top-3 left-3  z-10"
              />
            </Box>
          </div>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      id: "username",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
      hideable: false,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      id: "fullName",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "followers",
      headerName: "Followers",
      id: "followers",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "followings",
      headerName: "Followings",
      id: "followings",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "posts",
      headerName: "Posts",
      id: "posts",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "userId",
      headerName: "User Id",
      id: "userId",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "bio",
      headerName: "Bio",
      id: "bio",
      isChecked: false,
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      id: "status",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Chip
              label="Public"
              variant="filled"
              size="small"
              sx={{ color: "#1F9254", bgcolor: "#EBF9F1" }}
            />
          );
        } else {
          return (
            <Chip
              label="Private"
              variant="filled"
              size="small"
              sx={{ color: "#A30D11", bgcolor: "#FBE7E8" }}
            />
          );
        }
      },
    },

    {
      field: "isBusiness",

      headerName: "Is business",
      id: "isBusiness",
      isChecked: false,
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value) {
          return (
            <Chip
              label="Yes"
              variant="filled"
              size="small"
              sx={{ color: "#1F9254", bgcolor: "#EBF9F1" }}
            />
          );
        } else {
          return (
            <Chip
              label="No"
              variant="filled"
              size="small"
              sx={{ color: "#A30D11", bgcolor: "#FBE7E8" }}
            />
          );
        }
      },
    },
    {
      field: "bioLink",

      headerName: "Link in Bio",
      id: "bioLink",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.value.length != 0) {
          return (
            <Chip
              icon={<CheckCircleIcon color="#1F9254" />}
              variant="filled"
              size="small"
              sx={{ color: "#1F9254", bgcolor: "transparent" }}
            />
          );
        } else {
          return (
            <Chip
              icon={<CancelIcon color="#A30D11" />}
              variant="filled"
              size="small"
              sx={{ color: "#A30D11", bgcolor: "transparent" }}
            />
          );
        }
      },
    },
    {
      field: "email",

      headerName: "Email",
      id: "email",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile",

      headerName: "Mobile",
      id: "mobile",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "category",

      headerName: "Category",
      id: "category",
      isChecked: false,
      width: 150,
      headerAlign: "center",
      align: "center",
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   id: "action",
    //   isChecked: false,
    //   width: 70,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     console.log(params);
    //     return <IconButton>ggg</IconButton>;
    //   },
    // },
  ];

  const createRows = () => {
    let counter = 0;
    data.map((user) => {
      const row = {
        id: counter,
        profilePic: user.profile_pic_url,
        username: user.username,
        fullName: user.full_name,
        followers: user.follower_count,
        followings: user.following_count,
        posts: user.media_count,
        userId: user.pk_id,
        bio: user.biography || "-",
        status: user.is_private,
        email: user.public_email || "-",
        mobile: user.public_phone_number || "-",
        bioLink: user.bio_links,
        category: user.category || "-",
        isBusiness: user.is_business,
      };
      setRows((rows) => [...rows, row]);
      counter++;
    });
  };
  const fetchUser = async () => {
    try {
      const response = await instagramUser(username);
      if (response.status === 200) {
        setPage(response.data.data.user);
        setPageLoading(false);
      }
    } catch (err) {
      notify("An error acured!", "error");
    }
  };
  useEffect(() => {
    fetchUser();
    fetchFollowers();
  }, []);
  useEffect(() => {
    if (!tableLoading) {
      createRows();
    }
  }, [data]);

  return (
    <Box className="flex ">
      {open ? (
        <CustomModal page={modalContent} open={open} setOpen={setOpen} />
      ) : (
        // <Dialog
        //   open={open}
        //   children={<FollowerListFollower page={modalContent.page} result="true" />}
        // ></Dialog>
        ""
        // <CustomModal props={modalContent.page} />
      )}
      <main className="grid grid-cols-1 gap-y-4 gap-x-5 w-full overflow-x-hidden p-3">
        <Box
          className="fixed right-0"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sidebarWidth}px)`,
            },
            top: { xs: "48px", sm: "64px" },
            "& .MuiLinearProgress-root": {
              backgroundColor: "transparent",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#D4024B",
            },
            zIndex: 10,
          }}
        >
          <LinearProgress variant="determinate" value={100 / 1} />
        </Box>
        <Box
          component="section"
          className="add-section main-paper py-1 pb-6 flex flex-col"
        >
          <Typography
            fontSize={18}
            fontWeight={600}
            color={"black"}
            sx={{ mb: 2, mt: 1 }}
          >
            Followers Info
          </Typography>
          {pageLoading ? (
            <Profile Skeleton sx={{ width: "150px" }} />
          ) : (
            <FollowerListFollower page={page} result="true" />
          )}
        </Box>
        <Box
          component="section"
          className="add-section main-paper p-4 flex flex-row flex-wrap items-center "
        >
          <Box className="flex flex-row flex-wrap items-center pt-2 w-full">
            <Box sx={{ width: "100%", px: 2, py: 0 }}>
              {tableLoading ? (
                <TableSkeleton />
              ) : (
                <DataGrid
                  autoHeight
                  onRowClick={handleModalOpen}
                  stickyHeader
                  sx={{
                    gap: 1,
                    cursor: "pointer",
                    px: 2,
                    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                      display: "none",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: 700,
                    },
                  }}
                  columns={checkboxes}
                  rows={rows}
                  slots={{
                    toolbar: GridToolbarCustom,
                  }}
                  loading={tableLoading}
                />
              )}
            </Box>{" "}
          </Box>
        </Box>
      </main>
    </Box>
  );
};

export default FollowersInfo;
