import { Cancel, CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  Link,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Result = ({ sidebarWidth }) => {
  const location = useLocation();
  const { status, follower, type } = location.state;

  function time(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    if (d > 0) {
      return dDisplay;
    } else if (h > 0) {
      return hDisplay;
    } else if (m > 0) {
      return mDisplay;
    } else {
      return sDisplay;
    }
  }
  useEffect(() => {
    document.title = "Purchase Result - Binro";
  }, []);
  //rendering the elements
  return (
    <Box className="flex">
      <main className="grid grid-cols-1 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5 pb-[100px]">
        <Box
          className="fixed right-0"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sidebarWidth}px)`,
            },
            top: { xs: "48px", sm: "64px" },
            "& .MuiLinearProgress-root": {
              backgroundColor: "transparent",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#D4024B",
            },
            zIndex: 10,
          }}
        >
          <LinearProgress variant="determinate" value={100} />
        </Box>

        <Box
          component="section"
          className="result-section main-paper p-6 shadow-none"
        >
          <Grid className="max-w-md mx-auto text-center">
            {status === 200 ? (
              <>
                <CheckCircle className="text-8xl fill-green-600 mb-4" />
                <Typography
                  className="text-[#103132] text-3xl font-semibold mb-4"
                  variant="h4"
                >
                  Successful!
                </Typography>

                <Typography className="text-gray-500 text-base" variant="h6">
                  {type === "FL"
                    ? `Your report will be ready between ${time(
                        follower * 1
                      )} and ${time(follower * 3)}.`
                    : "    Your report will be ready between 3 and 10 days"}{" "}
                </Typography>
                <Box className="p-4">
                  <Button
                    component={Link}
                    href="/"
                    variant="outlined"
                    className="text-[#D4024B]"
                  >
                    Return to main page
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Cancel className="text-8xl fill-red-600 mb-4" />
                <Typography
                  className="text-[#103132] text-3xl font-semibold mb-4"
                  variant="h4"
                >
                  Failed!
                </Typography>
                <Typography className="text-gray-500 text-base" variant="h6">
                  The operation failed
                </Typography>
                <Box className="p-4">
                  <Button
                    component={Link}
                    href="/"
                    variant="outlined"
                    className="text-[#D4024B]"
                  >
                    Return to main page
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </main>
    </Box>
  );
};

export default Result;
