import React, { useEffect, useState, useRef } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import forgot_password_vector from "../../assets/images/forgot-password-vector.jpg";
import { validate } from "../../components/validate";
import { notify } from "../../components/notify/toast";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPass } from "../../apis";

const ForgotPassword = () => {
  const emailRef = useRef(null);
  const [data, setData] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    document.title = "Forgot Password - Binro";
  }, []);

  useEffect(() => {
    setErrors(validate(data, "forgot-password"));
  }, [data, touched]);

  const changeHandler = (event) => {
    if (event.target.name === "is_accepted") {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }

    // console.log(!Object.keys(errors).length);
  };

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  //handling response and errors of the request
  
  const submitHandler = async (event) => {
    event.preventDefault();
    try{
      const response = await forgotPass({
        email: emailRef.current.value,
      });
      //console.log(response.status)
      if (response.status === 200) {
        notify(
          "An email containing a password change link has been sent to you!",
          "success"
        );
        // console.log("200", response);
      } 
    }
    catch(err){
      //console.log(err)
      if (err.response.status === 400) {
        setErrors(data);
        // console.log(response);
      } else if (err.response.status === 404) {
        //console.log("404 err");
        notify("User does not exist.", "error");
      } else if (err.response.status >= 500) {
        notify("Server Error!", "error");
      } else {
        //console.log(Object.keys(errors).length);
        notify("This email does not exist!", "error");
        setTouched({
          email: true,
        });
      }
    }
   
  };
  //rendering page elements:
  return (
    <Box className="app flex justify-center items-center w-full min-h-screen bg-[#102132] sm:p-12">
      <Box className="bg-white max-w-5xl mx-auto flex flex-wrap items-stretch w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)] p-4 sm:rounded-xl sm:min-h-fit">
        <Box className="form-logo flex-1 min-w-full self-start flex justify-between items-center p-4 pb-0">
          <Box className="logo w-20">
            <img src={logo} alt="binro logo" />
          </Box>
        </Box>
        <Box className="form-vector w-1/4 hidden md:w-[450px] lg:w-[514px] md:flex">
          <Box className="flex items-center justify-center p-8">
            <img
              className="object-contain"
              src={forgot_password_vector}
              alt=""
            />
          </Box>
        </Box>
        <Box className="account-form flex-1">
          <Box className="flex flex-col justify-between h-full gap-4 p-5">
            <Box className="account-form-header hidden justify-between items-center md:flex"></Box>
            <Box className="account-form-body max-w-sm mx-auto">
              <Box className="form-heading mb-8">
                <Typography variant="h5" fontWeight={600}>
                  Password Recovery
                </Typography>
                <Typography variant="caption">
                  If the account exists, we'll email you a link to reset the
                  password.
                </Typography>
              </Box>
              <Box className="form-inputs">
                <form onSubmit={submitHandler}>
                  <Box className="grid grid-cols-2 gap-5 mb-3">
                    <TextField
                      inputRef={emailRef}
                      type="email"
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.email}
                      className="col-span-2"
                      name="email"
                      label="Email"
                      placeholder="elisabeth123@example.com"
                      variant="outlined"
                      size="small"
                      helperText={errors.email && touched.email && errors.email}
                      autoComplete="off"
                      required
                    />
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className="bg-[#D4024B] capitalize shadow-none mt-3"
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </Box>
            <Box className="account-form-footer text-center">
              <Typography variant="caption" fontSize={14}>
                Do you want to create a new account?
                <Link className="font-semibold ml-3" to="/sign-up">
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default ForgotPassword;
