import React from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";

const ProfileSkeleton = ({ withoutButton }) => {
  return (
    <Box className="analysis-box grid grid-cols-1 sm:grid-cols-[auto_175px] gap-4 py-6">
      <Box className="flex gap-4 col-span-1">
        <Box className="analysis-progress text-[#D4024B]">
          <Skeleton className="w-16 h-16" variant="circular" />
        </Box>
        <Box className="analysis-info col-span-1">
          <Box className="analysis-box-top mb-3">
            <Skeleton width={100} variant="text" />
          </Box>
          <Box className="analysis-box-bottom">
            <ul className="analysis-box-bottom--list">
              <li>
                <Skeleton className="w-4 h-4 mr-2" variant="circular" />
                <Skeleton width={100} variant="text" />
              </li>
              <li>
                <Skeleton className="w-4 h-4 mr-2" variant="circular" />
                <Skeleton width={100} variant="text" />
              </li>
              <li>
                <Skeleton className="w-4 h-4 mr-2" variant="circular" />
                <Skeleton width={100} variant="text" />
              </li>
            </ul>
          </Box>
        </Box>
      </Box>

      <Box className="analysis-download mx-auto self-center">
        {!withoutButton && (
          <Skeleton
            width={163}
            height={44}
            className="rounded-3xl -lg:h-10"
            variant="rectangular"
          />
        )}
      </Box>
    </Box>
  );
};

export default ProfileSkeleton;
