import React from "react";

const FileChartLine = ({ className, size = 22 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 14 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85547 11.5H4.04688C3.83594 11.5 3.625 11.7461 3.625 11.957V14.4531C3.625 14.6641 3.83594 14.875 4.04688 14.875H4.85547C5.06641 14.875 5.3125 14.6641 5.3125 14.4531V11.957C5.3125 11.7461 5.06641 11.5 4.85547 11.5ZM7.38672 9.25H6.57812C6.36719 9.25 6.15625 9.49609 6.15625 9.70703V14.4531C6.15625 14.6641 6.36719 14.875 6.57812 14.875H7.38672C7.59766 14.875 7.84375 14.6641 7.84375 14.4531V9.70703C7.84375 9.49609 7.59766 9.25 7.38672 9.25ZM9.10938 14.875H9.91797C10.1289 14.875 10.375 14.6641 10.375 14.4531V10.832C10.375 10.6211 10.1289 10.375 9.91797 10.375H9.10938C8.89844 10.375 8.6875 10.6211 8.6875 10.832V14.4531C8.6875 14.6641 8.89844 14.875 9.10938 14.875ZM13.2227 3.69531L10.2695 0.777344C9.95312 0.460938 9.53125 0.25 9.10938 0.25H1.90234C0.988281 0.285156 0.25 1.02344 0.25 1.97266V16.5977C0.25 17.5117 0.988281 18.25 1.90234 18.25H12.0273C12.9766 18.25 13.75 17.5117 13.75 16.5977V4.89062C13.75 4.46875 13.5391 4.01172 13.2227 3.69531ZM9.21484 2.07812L11.9219 4.78516H9.21484V2.07812ZM12.0625 16.5977H1.90234V1.97266H7.52734V5.62891C7.52734 6.08594 7.91406 6.47266 8.37109 6.47266H12.0625V16.5977Z"
      />
    </svg>
  );
};

export { FileChartLine };
