import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { CircularProgressWithLabel } from "../../components/progress/CircularProgress";
import { ChevronRight } from "@mui/icons-material";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { LoaderCircle } from "../../components/icons/LoaderCircle";
import {
  adminReports,
  adminOrders,
  reports as userReports,
  orders as userOrders,
  reanalysis,
} from "../../apis";
import ReportBoxSkeleton from "../../components/skeleton/ReportBoxSkeleton";
import { UserInfoContext } from "../../App";
import { API_URL } from "../../httpClients";
import { Link } from "react-router-dom";
import { notify } from "../../components/notify/toast";
import FollowersInfo from "../follower-list-info/results";
import { useNavigate } from "react-router-dom";
import emptyState from "../../assets/images/No data.gif";

// import axios from "axios";

const progressValue = (state) => {
  switch (state) {
    case "RD":
      return 0;
    case "AD":
      return 40;
    case "GR":
      return 80;
    case "DR":
      return 100;
    default:
      return 0;
  }
};

const Reports = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openDialogId, setOpenDialogId] = useState(-1);
  const [reanalysisLoading, setReanalysisLoading] = useState(false);
  const [reanalysisError, setReanalysisError] = useState("");

  const UserInfo = useContext(UserInfoContext);
  const { is_staff, referral_source } = UserInfo.data;
  let navigate = useNavigate();

  const handleOpenDialog = (id) => {
    setOpenDialogId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialogId(-1);
    setReanalysisError(undefined);
  };

  const fetchReports = async () => {

    // try {
    // Fetch orders based on the user role

    // const order_response = await (is_staff ? adminOrders() : userOrders());
    // console.log(order_response);

    // if (order_response.status === 200) {
    try {
      const report_response = await (is_staff ? adminReports() : userReports());

      // console.log(report_response);

      if (report_response.status === 200) {
        // console.log(report_response);
        const total_reports = report_response.data;
        // console.log(total_reports);

        const paid_reports = total_reports.filter(
          (report) => report.status.toLowerCase() === "p"
        );
        // const paid_orders_reports = reports.reverse().filter((report) => {
        //   return paid_orders.some((order) => {
        //     return order.report === report.id;
        //   });
        // });
console.log(total_reports)
        setReports(paid_reports);
      }
      // }
    } catch (error) {
      // Catch any general errors that occur
      setError("Server Error!");
    } finally {
      //  }
      // else if (order_response.status >= 400) {
      //   setLoading(false);
      //   setError("Server Error!");
      // }
      // } catch (error) {
      //   // Catch any errors that occur during report fetch

      //   setError("Server Error!");
      // }
      // Set the loading state to false regardless of the outcome

      setLoading(false);
    }
  };

  const fetchReanalysis = async (lastUpdate, id) => {
    // if (
    //   new Date(lastUpdate).getTime() + 30 * 24 * 60 * 60 * 1000 >=
    //   new Date().getTime()
    // ) {
    //   setReanalysisError(
    //     "At least 30 days must have passed since the creation or reanalysis of the report."
    //   );
    // } else {
    setReanalysisLoading(true);
    setReanalysisError(undefined);

    try {
      const response = await reanalysis({
        report_id: id,
      });

      // console.log(response);

      if (response.status === 200) {
        notify(response.data.message, "success");
        setOpenDialogId(-1);
        // console.log(response);
      }
      if (response.status >= 400) {
        // console.log(response);
      }
    } catch (error) {
      // console.log(error);
      setReanalysisError(error.response.data.message);
    } finally {
      setReanalysisLoading(false);
    }
    // }
  };
  //get orders from back
  useEffect(() => {
    document.title = "Reports - Binro";

    fetchReports();
  }, [reports]);
  // const showFollowersInfo = (report) => {
  //   navigate("/followersListResult", {
  //     state: {
  //       report_id: report.id,
  //     },
  //   });
  // };
  //rendering the page elements
  return (
    <Box className="flex">
      <main className="grid grid-cols-1 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5">
        <Box component="section" className="resent-section main-paper">
          <Box className="main-paper-title flex justify-between items-center mb-3">
            <Typography
              className="section-title-tpg"
              variant="subtitle1"
              fontWeight={600}
            >
              Recent Analysis Request
            </Typography>
          </Box>
          <Box className="main-paper-body">
            <Box className="analysis-boxes flex flex-col">
              {error && error}

              {/* {loading
                ? ""
                : reports.map((item, index) => (
                    <div key={item.id}>
                      <Button onClick={() => handleOpenDialog(index)}>
                        Show {item}
                      </Button>
                      <Dialog
                        open={openDialogIndex === index}
                        onClose={handleCloseDialog}
                      >
                        <DialogTitle>{item}</DialogTitle>
                        <DialogContent>
                          This is the content for {item}.
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog}>Close</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  ))} */}

              {loading ? (
                <>
                  <ReportBoxSkeleton />
                  <ReportBoxSkeleton />
                </>
              ) : !reports.length ? (
                <Box className=" w-full  flex flex-col items-center justify-center ">
                  <img className="object-contain w-[250px] sm:w-[350px]" src={emptyState} />
                  <Typography fontSize={13} color={"rgba(0, 0, 0, 0.6)"}>
                  No report is available, order your first report to see it here!
                  </Typography>
                </Box>
              ) : (
                reports.reverse().map((report) => (
                  <Box
                    key={report.id}
                    className="analysis-box grid grid-cols-1 sm:grid-cols-[auto_175px] gap-4 py-6"
                  >
                    <Box className="flex gap-4 col-span-1">
                      <Box className="analysis-progress text-[#D4024B]">
                        <CircularProgressWithLabel
                          value={progressValue(report.state)}
                        />
                      </Box>
                      <Box className="analysis-info mr-auto">
                        <Box className="analysis-box-top">
                          <Link
                            to="/reports"
                            className="report-title text-black flex items-center gap-x-2 no-underline font-medium mb-1"
                          >
                            {report.type.toLowerCase() === "ca"
                              ? `${report.name} Analysis`
                              : `${report.name} Followers list info`}
                            <ChevronRight
                              fontSize="small"
                              className="text-[#969696]"
                            />
                          </Link>
                          <Typography
                            color="primary"
                            fontSize={10}
                            fontWeight={600}
                            className="mb-3 ml-2"
                          >
                            {report.type.toLowerCase() === "ca"
                              ? `Influencers Selection`
                              : `Followers list info`}
                          </Typography>
                        </Box>
                        <Box className="analysis-box-bottom">
                          <ul className="analysis-box-bottom--list">
                            <li>
                              {report.state === "AD" ||
                              report.state === "GR" ||
                              report.state === "DR" ? (
                                <CheckCircle />
                              ) : report.state === "RD" ? (
                                <LoaderCircle className="animate-spin" />
                              ) : (
                                <LoaderCircle />
                              )}
                              Receiving Data
                            </li>
                            <li>
                              {report.state === "GR" ||
                              report.state === "DR" ? (
                                <CheckCircle />
                              ) : report.state === "AD" ? (
                                <LoaderCircle className="animate-spin" />
                              ) : (
                                <LoaderCircle />
                              )}
                              Analyzing Data
                            </li>
                            <li>
                              {report.state === "DR" ? (
                                <CheckCircle />
                              ) : report.state === "GR" ? (
                                <LoaderCircle className="animate-spin" />
                              ) : (
                                <LoaderCircle />
                              )}
                              Generating Report
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="analysis-download grid gap-2 col-span-1 mx-auto self-center mt-2 sm:mt-0">
                      {report.type.toLowerCase() === "ca" ? (
                        report.state === "DR" ? (
                          <Button
                            component={Link}
                            to={API_URL + report.file}
                            variant="contained"
                            endIcon={<ChevronRight />}
                            size="small"
                            className="bg-[#D4024B] w-fit px-3 h-11 capitalize rounded-lg shadow-none mb-7"
                            disabled={
                              report.state === "DR" && !report.update_request
                                ? false
                                : true
                            }
                          >
                            Download Report
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            endIcon={<ChevronRight />}
                            size="small"
                            className="bg-[#D4024B] w-fit px-3 h-11 capitalize rounded-lg shadow-none mb-7"
                            disabled={report.state.toLowerCase() === "dr" ? false : true}
                          >
                            Download Report
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={report.state === "DR" ? false : true}
                          onClick={() => {
                            navigate("/followersListResult", {
                              state: {
                                report_id: report.id,
                                username: report.name,
                                userId: report.userid_list,
                              },
                            });
                          }}
                          variant="contained"
                          endIcon={<ChevronRight />}
                          size="small"
                          className="bg-[#D4024B] w-fit px-3 h-11 capitalize rounded-lg shadow-none mb-7"
                        >
                          Show report
                        </Button>
                      )}

                      {referral_source !== null &&
                        referral_source.toLowerCase() === "appsumo" && (
                          <>
                            <Button
                              sx={{
                                "&.Mui-disabled": {
                                  color: "currentcolor",
                                },
                              }}
                              onClick={() => handleOpenDialog(report.id)}
                              className="flex justify-between px-4 h-10 rounded-3xl bg-[#D4024B]/10 text-[#D4024B] capitalize py-1"
                              endIcon={<ChevronRight />}
                            >
                              {report.update_request
                                ? "Processing..."
                                : "Reanalysis"}
                            </Button>
                            <Dialog
                              open={openDialogId === report.id}
                              onClose={handleCloseDialog}
                              aria-labelledby="responsive-dialog-title"
                            >
                              <DialogTitle>
                                Are you sure you want to reanalyze?
                                {report.id}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  You can re-analyze once a month This feature
                                  is available forever for all your reports Only
                                  for users with LifeTime access
                                </DialogContentText>
                                {report.update_request && (
                                  <DialogContentText
                                    sx={{
                                      p: "10px",
                                      mt: 2,
                                      color: "#D4024B",
                                      fontWeight: "500",
                                      border: "1px solid #D4024B",
                                      textAlign: "center",
                                      borderRadius: 2,
                                      backgroundColor: "#D4024B1A",
                                    }}
                                  >
                                    Your report is being reanalyzed
                                  </DialogContentText>
                                )}
                                {report.state !== "DR" &&
                                  !report.update_request && (
                                    <DialogContentText
                                      sx={{
                                        p: "10px",
                                        mt: 2,
                                        color: "#D4024B",
                                        fontWeight: "500",
                                        border: "1px solid #D4024B",
                                        textAlign: "center",
                                        borderRadius: 2,
                                        backgroundColor: "#D4024B1A",
                                      }}
                                    >
                                      Your request has not been processed yet
                                    </DialogContentText>
                                  )}
                                {reanalysisError && (
                                  <FormHelperText>
                                    {reanalysisError}
                                  </FormHelperText>
                                )}
                              </DialogContent>
                              <DialogActions>
                                <Button autoFocus onClick={handleCloseDialog}>
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() =>
                                    fetchReanalysis(
                                      report.last_updated,
                                      report.id
                                    )
                                  }
                                  sx={{
                                    color: "#026100",
                                    "&:hover": {
                                      backgroundColor: "#00FF2940!important",
                                    },
                                  }}
                                  endIcon={
                                    reanalysisLoading && (
                                      <CircularProgress
                                        sx={{
                                          circle: {
                                            stroke: "#026100",
                                          },
                                        }}
                                        size={18}
                                      />
                                    )
                                  }
                                  autoFocus
                                  disabled={report.state !== "DR"}
                                >
                                  Continue
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
      </main>
    </Box>
  );
};

export default Reports;
