import React, { createContext, useEffect, useState } from "react";
import NoInternetConnection from "./NoInternetConnection";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/index";
import CampaignsTracker from "./pages/campaigns-tracker";
import InfluencersSelection from "./pages/influencers-selection";
import Payment from "./pages/influencers-selection/payment";
import Result from "./pages/influencers-selection/result";
import CompetitorTracker from "./pages/competitor-tracker";
import Help from "./pages/help";
import MarketAnalysis from "./pages/market-analysis";
import Reports from "./pages/reports";
import Settings from "./pages/settings";
// import Account from "./pages/account/Account";
import useToken from "./useToken";
import Drawer from "./components/layouts/Drawer";
import { Box } from "@mui/system";
import { Toolbar } from "@mui/material";
import NoMatch from "./pages/NoMatch";
import { getUser } from "./apis";
import SignIn from "./pages/account/sign-in";
import SignUp from "./pages/account/sign-up";
import ForgotPassword from "./pages/account/forgot-password";
import ResetPassword from "./pages/account/reset-password";
import Success from "./pages/payment/success";
import Failed from "./pages/payment/failed";
import Cancled from "./pages/payment/cancelled";
import FolloweList from "./pages/follower-list-info/index";
import Packages from "./pages/credits/packages";
import FollowersInfo from "./pages/follower-list-info/results";
// import { ToastContainer } from "react-toastify";

const sidebarWidth = 285;

export const UserInfoContext = createContext();

const App = () => {
  const { token, setToken } = useToken();
  const [userInfo, setUserInfo] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [userError, setUserError] = useState("");

  const fetchGetUser = async () => {
    try {
      const response = await getUser();
      if (response.status === 200) {
        setUserInfo(response.data);
        // console.log(response);
      } else if (response.status >= 400) {
        setUserError("Server Error");
      }
    } catch (e) {
      // setUserInfo({});
      setUserError("Server Error");
    } finally {
      setUserLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchGetUser();
    }
  }, [token]);

  return (
    <div>
      {token ? (
        <>
          <Box className="flex">
            <UserInfoContext.Provider
              value={{
                data: userInfo,
                loading: userLoading,
                error: userError,
                setUser: setUserInfo,
              }}
            >
              <Drawer sidebarWidth={sidebarWidth} />
              <Box className="w-full">
                <Toolbar />
                <Routes>
                  <Route index element={<Dashboard />} />
                  <Route path="/dashboard" element={<Navigate to="/" />} />
                  <Route
                    path="influencers-selection"
                    element={
                      <InfluencersSelection sidebarWidth={sidebarWidth} />
                    }
                  />
                  <Route
                    path="influencers-selection/payment"
                    element={<Payment sidebarWidth={sidebarWidth} />}
                  />
                  <Route
                    path="result"
                    element={<Result sidebarWidth={sidebarWidth} />}
                  />
                  <Route
                    path="followerList"
                    element={<FolloweList sidebarWidth={sidebarWidth} />}
                  />
                  <Route
                    path="followersListResult"
                    element={<FollowersInfo sidebarWidth={sidebarWidth} />}
                  />
                  <Route path="sign-in" element={<Navigate to="/" />} />
                  <Route path="sign-up" element={<Navigate to="/" />} />
                  <Route
                    path="campaigns-tracker"
                    element={<CampaignsTracker />}
                  />
                  <Route
                    path="competitor-tracker"
                    element={<CompetitorTracker />}
                  />

                  <Route path="market-analysis" element={<MarketAnalysis />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="help" element={<Help />} />
                  <Route path="settings" element={<Settings />} />
                  <Route
                    path="demo"
                    element={<Navigate to="https://binro.io/support" />}
                  />
                  <Route path="*" element={<NoMatch />} />
                  <Route path="packages" element={<Packages />} />
                  <Route exact path="stripe/success/" element={<Success />} />
                  <Route exact path="stripe/cancel/" element={<Cancled />} />
                </Routes>
                <NoInternetConnection sidebarWidth={sidebarWidth} />
              </Box>
            </UserInfoContext.Provider>
          </Box>
        </>
      ) : (
        <Box>
          <Routes>
            <Route index element={<SignIn setToken={setToken} />} />
            <Route path="sign-in" element={<Navigate to="/" />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="forgot-password/" element={<ForgotPassword />} />
            <Route
              path="forgot-password/confirm/:var"
              element={<ResetPassword />}
            />
            <Route path="stripe/failed/" element={<Failed />} />
          </Routes>
        </Box>
      )}
    </div>
  );
};

export default App;

// import React from "react";
// import PdfViewerComponent from "./components/PdfViewerComponent";

// // function DocumentViewerComponent() {
// //   return (
// //     <div className="PDF-viewer">
// //       <PdfViewerComponent document={"document.pdf"} />
// //     </div>
// //   );
// // }
// const App = () => {
//   return (
//     <div className="App">
//       <div className="PDF-Viewer">
//         <PdfViewerComponent
//           document={"https://api.binro.io/uploads/reports/pdf-test.pdf"}
//         ></PdfViewerComponent>
//       </div>
//     </div>
//   );
// };

// export default App;
