import axios from "axios";
import Cookies from "js-cookie";

export const API_URL = process.env.REACT_APP_API_URL;

const getToken = () => {
  const tokenString = sessionStorage.getItem("token");
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const token = getToken();



const httpClient = axios.create({
  baseURL: API_URL,
  responseType: "json",
  timeout: 50000,
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  maxBodyLength: Infinity,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: token ? `TOKEN ${token}` : null,
  },
});

export async function callApi(url, body, method = "post", config, headers) {
  
  return httpClient({
    method: method,
    url: url,
    data: body,
    headers: headers,
    ...config,
  });
  
}
