import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import reset_password_vector from "../../assets/images/reset-password-vector.png";
import { validate } from "../../components/validate";
import { notify } from "../../components/notify/toast";
import ShowHidePassword from "../../components/inputs/ShowHidePassword";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { passComplete } from "../../apis";

const ResetPassword = () => {
  const pwdRef = useRef(null);
  const pwd2ref = useRef(null);

  let navigate = useNavigate();

  var par = useParams();
  const [data, setData] = useState({
    new_password1: "",
    new_password2: "",
  });
  const [backData, setBackData] = useState({
    token: par.var,
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    document.title = "Confirm Password - Binro";
  }, []);

  useEffect(() => {
    setErrors(validate(data, "reset-password"));
  }, [data, touched]);

  const changeHandler = (event) => {
    if (event.target.name === "is_accepted") {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };

  //handling response and errors of the request
  useEffect(() => {
    setBackData({
      ...backData,
      token: par.var,
      password: data.new_password1,
    });
  }, [data]);
  const submitHandler = async (event) => {
    event.preventDefault();

    if (data.new_password1 === data.new_password2) {
      // console.log(backData);
      try {
        const response = await passComplete(backData);
        if (response.status === 200) {
          //console.log(response);
          if (response.data.status === "OK") {
            notify("Your password has been changed!", "success");
            setTimeout(() => {
              return navigate("/sign-in");
            }, 2000);
          } else {
            notify(
              "Your link is expired. You are redirecting to recover password page.",
              "error"
            );
            setTimeout(() => {
              return navigate("/forgot-password/");
            }, 2000);
          }
        }
      } catch (err) {
        //console.log(err);
        if (err.response.status === 400) {
          setErrors({
            new_password1: "password is invalid.",
            new_password2: "confirm password is invalid.",
          });
          notify(err.response.data.password[0], "error");
        } else if (err.response.status >= 500) {
          notify("Server Error!", "error");
        }
      }
    } else {
      notify("Password and Confirm password should be same!", "error");
      setTouched({
        pwdref: true,
        pwd2ref: true,
      });
    }
  };
  //rendering page elements:
  return (
    <Box className="app flex justify-center items-center w-full min-h-screen bg-[#102132] sm:p-12">
      <Box className="bg-white max-w-5xl mx-auto flex flex-wrap items-stretch w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)] p-4 sm:rounded-xl sm:min-h-fit">
        <Box className="form-logo flex-1 min-w-full self-start flex justify-between items-center p-4 pb-0">
          <Box className="logo w-20">
            <img src={logo} alt="binro logo" />
          </Box>
        </Box>
        <Box className="form-vector w-1/4 hidden md:w-[450px] lg:w-[514px] md:flex">
          <Box className="flex items-center justify-center p-8">
            <img
              className="object-contain"
              src={reset_password_vector}
              alt=""
            />
          </Box>
        </Box>
        <Box className="account-form flex-1">
          <Box className="flex flex-col justify-between h-full gap-4 p-5">
            <Box className="account-form-header hidden justify-between items-center md:flex"></Box>
            <Box className="account-form-body max-w-sm mx-auto">
              <Box className="form-heading mb-8">
                <Typography variant="h5" fontWeight={600}>
                  Change your password
                </Typography>
              </Box>
              <Box className="form-inputs">
                <form onSubmit={submitHandler}>
                  <Box className="grid grid-cols-2 gap-5 mb-3">
                    <ShowHidePassword
                      forwardedRef={pwdRef}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.new_password1}
                      className="col-span-2 capitalize"
                      name="new_password1"
                      label="Password"
                      size="small"
                      helperText={
                        errors.new_password1 &&
                        touched.new_password1 &&
                        errors.new_password1
                      }
                    />
                    <ShowHidePassword
                      forwardedRef={pwd2ref}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.new_password2}
                      className="col-span-2 capitalize"
                      name="new_password2"
                      label="Confirm Password"
                      size="small"
                      helperText={
                        errors.new_password2 &&
                        touched.new_password2 &&
                        errors.new_password2
                      }
                    />
                  </Box>
                  <Button
                    type="submit"
                    variant="contained"
                    className="bg-[#D4024B] capitalize shadow-none mt-3"
                  >
                    Change password
                  </Button>
                </form>
              </Box>
            </Box>
            <Box className="account-form-footer text-center">
              <Typography variant="caption" fontSize={14}>
                Do you want to create a new account?
                <Link className="font-semibold ml-3" to="/sign-up">
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default ResetPassword;
