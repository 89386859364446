import React, { useEffect, useState } from "react";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { notify } from "../../components/notify/toast";
import { successfulPayment } from "../../apis";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

const Success = () => {
  const [order, SetOrder] = useState({});
  const [successful, setSuccessful] = useState();
  const [isLoading, setIsLoading] = useState(false);

  var par = parseInt(useParams().var, 10);
  const fetchpayment = async () => {
    setIsLoading(true);

    try {
      const response = await successfulPayment(par);
      // const parsed= JSON.parse(response)
      // console.log(parsed)
      if (response.status === 200) {
        SetOrder(response.data);
        console.log(order);
        setSuccessful(true);
        // console.log("200", response);
      }
    } catch (err) {
      console.log(err);
      setSuccessful(false);
      if (err.response.status === 400) {
        notify("No access to this order!", "error");
        // console.log(response);
      } else if (err.response.status >= 500) {
        notify("Server Error!", "error");
      } else {
        notify("This email does not exist!", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };
  // useEffect(() => {
  //   fetchpayment();
  // }, []);

  return (
    <div>
      {isLoading ? (
        <Box className="app flex justify-center items-center w-full min-h-screen bg-[#102132] sm:p-12">
          <Box className="bg-white space-y-12 max-w-2xl max-h-full mx-auto flex flex-col py-36 px-24 flex-wrap justify-between items-center w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)]  sm:rounded-xl sm:min-h-fit">
            <Typography varient="h1" fontWeight={600}>
              Loading...
            </Typography>
            <CircularProgress
              sx={{
                circle: {
                  stroke: "#D10D4B",
                },
              }}
              size={50}
            />
          </Box>
        </Box>
      ) : (
        <div>
          <Box className="app flex justify-center items-center w-full min-h-screen  sm:p-12">
            <Box className="bg-white space-y-12 max-w-2xl max-h-full mx-auto flex flex-col py-24 px-24 flex-wrap justify-between items-center w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)]  sm:rounded-xl sm:min-h-fit">
              <Box className="flex space-y-3 flex-col justify-between items-center ">
                <Box className="bg-[#E4F3ED] rounded-full w-20 h-20 flex justify-center items-center">
                  <CheckCircleIcon className="w-10 h-10" color="success" />
                </Box>
                <Typography
                  varient="h1"
                  fontWeight={600}
                  className="text-[#2E7D32] text-2xl"
                >
                  Payment succeeded!
                </Typography>
              </Box>

              {/* <Divider className="w-11/12" />
              <Box className="space-y-3.5 flex flex-col justify-between items-center max-w-5xl mx-auto w-full ">
                <Box className="flex flew-row justify-between items-center  max-w-5xl mx-auto w-full">
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    className="text-[#666666]"
                  >
                    Order ID
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    className="text-[#2E7D32]"
                  >
                    {order.id}
                  </Typography>
                </Box>
                <Box className="flex flew-row justify-between items-center  max-w-5xl mx-auto w-full">
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      className="text-[#666666]"
                    >
                      Payment Time
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      className="text-[#2E7D32]"
                    >
                      Null
                    </Typography>
                  </Box>
                <Box className="flex flew-row justify-between items-center  max-w-5xl mx-auto w-full">
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    className="text-[#666666]"
                  >
                    Amount
                  </Typography>
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    className="text-[#2E7D32]"
                  >
                    {order.price}
                  </Typography>
                </Box>
              </Box> */}
              <Divider className="w-11/12" />
              <Link
                className="text-[#D4024B] font-semibold ml-3 cursor-pointer"
                to="/dashboard"
              >
                Back to dashboard{" "}
              </Link>
            </Box>

            {/* <ToastContainer /> */}
          </Box>
        </div>
      )}
    </div>
  );
};
export default Success;
