import React, { useContext, useEffect, useState } from "react";
import { Button, Typography, Box } from "@mui/material";
import { Magnifier } from "../../components/icons/Magnifier";
import OutlineBlackButton from "../../components/buttons/OutlineBlackButton";
import { CircularProgressWithLabel } from "../../components/progress/CircularProgress";
import { ChevronRight } from "@mui/icons-material";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { LoaderCircle } from "../../components/icons/LoaderCircle";
import emptyState from "../../assets/images/No data.gif";
import emptyState1 from "../../assets/images/No data1.gif";

import {
  adminOrders,
  adminReports,
  orders as userOrders,
  reports as userReports,
} from "../../apis";
import ReportBoxSkeleton from "../../components/skeleton/ReportBoxSkeleton";
import ReportListSkeleton from "../../components/skeleton/ReportListSkeleton";
import { UserInfoContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../httpClients";

const LATEST_REPORTS_BOX_COUNT = 2;
const LATEST_REPORTS_LIST_COUNT = 6;

const progressValue = (state) => {
  switch (state) {
    case "RD":
      return 0;
    case "AD":
      return 40;
    case "GR":
      return 80;
    case "DR":
      return 100;
    default:
      return 0;
  }
};

const Dashboard = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [latestReportsBox, setLatestReportsBox] = useState([]);
  const [latestReportsList, setLatestReportsList] = useState([]);

  const UserInfo = useContext(UserInfoContext);
  const { is_staff } = UserInfo.data;
  const navigate = useNavigate();

  const fetchReports = async () => {
    // try {
    // Fetch orders based on the user role

    // const order_response = await (is_staff ? adminOrders() : userOrders());
    // console.log(order_response);

    // if (order_response.status === 200) {
    try {
      const report_response = await (is_staff ? adminReports() : userReports());

      // console.log(report_response);

      // if (report_response.status === 200) {
      // console.log(report_response);
      const total_reports = report_response.data;
      // console.log(total_reports);

      const paid_reports = total_reports.filter(
        (report) => report.status.toLowerCase() === "p"
      );
      // const paid_orders_reports = reports.reverse().filter((report) => {
      //   return paid_orders.some((order) => {
      //     return order.report === report.id;
      //   });
      // });

      setReports(paid_reports);
      setLatestReportsBox(
        paid_reports.filter((report) => report.state.toLowerCase() !== "dr")
      );
      setLatestReportsList(
        paid_reports.filter((report) => report.state.toLowerCase() === "dr")
      );
      // }
    } catch (error) {
      // Catch any general errors that occur
      setError("Server Error!");
    } finally {
      //  }
      // else if (order_response.status >= 400) {
      //   setLoading(false);
      //   setError("Server Error!");
      // }
      // } catch (error) {
      //   // Catch any errors that occur during report fetch

      //   setError("Server Error!");
      // }
      // Set the loading state to false regardless of the outcome

      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();

    document.title = "Dashboard - Binro";
  }, [reports]);

  return (
    <main className="grid grid-cols-2 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5">
      <Box
        component="section"
        className="start-section main-paper py-10 min-h-[300px] col-span-2"
      >
        <div className="main-paper-title mb-7">
          <Typography
            className="section-title-tpg"
            variant="subtitle1"
            fontWeight={600}
          >
            Let's start exploring competing audiences
          </Typography>
        </div>
        <ul className="start-section-list mb-7">
          <li>User Relation Analysis</li>
          <li>Campaigns Tracking</li>
          <li>Competitor Tracking</li>
          <li>Content Analysis</li>
        </ul>
        <Button
          component={Link}
          to="/influencers-selection"
          endIcon={<Magnifier className="fill-white" />}
          variant="contained"
          className="bg-[#D4024B] w-fit px-5 h-12 capitalize rounded-lg shadow-none mb-7"
          sx={{
            ".MuiButton-endIcon": {
              mr: 0,
              ml: "5",
            },
          }}
        >
          Start Now
        </Button>
      </Box>

      <Box
        component="section"
        className="resent-section main-paper col-span-2 lg:col-span-1 h-fit"
      >
        <div className="main-paper-title flex justify-between items-center mb-3">
          <Typography
            className="section-title-tpg"
            variant="subtitle1"
            fontWeight={600}
          >
            Recent Analysis Request
          </Typography>
          <Link className="no-underline" to="/reports">
            <OutlineBlackButton className="pointer-events-none" />
          </Link>
        </div>
        <div className="main-paper-body">
          <div className="analysis-boxes">
            {loading ? (
              <>
                <ReportBoxSkeleton withoutButton />
                <ReportBoxSkeleton withoutButton />
              </>
            ) : latestReportsBox.length===0 ? (
              <Box className="w-full  flex flex-col items-center justify-center mt-5">
                <img className="object-contain w-[150px] sm:w-[250px]" src={emptyState1} />
                <Typography fontSize={10} color={"rgba(0, 0, 0, 0.6)"} >
                No report is in progress, order your first report to see it here!
                </Typography>
              </Box>
            ) : (
              latestReportsBox
                .slice(-LATEST_REPORTS_BOX_COUNT)
                .reverse()
                .map((report) => (
                  <div key={report.id} className="analysis-box flex gap-4 py-6">
                    <div className="analysis-progress text-[#D4024B]">
                      <CircularProgressWithLabel
                        value={progressValue(report.state)}
                      />
                    </div>
                    <div className="analysis-info">
                      <div className="analysis-box-top">
                        <Link
                          to="/reports"
                          className="report-title text-black flex items-center gap-x-2 no-underline font-medium mb-1"
                        >
                          {report.type.toLowerCase() === "ca"
                            ? `${report.name} Analysis`
                            : `${report.name} Followers list info`}
                          <ChevronRight
                            fontSize="small"
                            className="text-[#969696]"
                          />
                        </Link>
                        <Typography
                          color="primary"
                          fontSize={10}
                          fontWeight={600}
                          className="mb-3 ml-2"
                        >
                          {report.type.toLowerCase() === "ca"
                            ? `Influencers Selection`
                            : `Followers list info`}
                        </Typography>
                      </div>
                      <div className="analysis-box-bottom">
                        <ul className="analysis-box-bottom--list">
                          <li>
                            {report.state === "AD" ||
                            report.state === "GR" ||
                            report.state === "DR" ? (
                              <CheckCircle />
                            ) : report.state === "RD" ? (
                              <LoaderCircle className="animate-spin" />
                            ) : (
                              <LoaderCircle />
                            )}
                            Receiving Data
                          </li>
                          <li>
                            {report.state === "GR" || report.state === "DR" ? (
                              <CheckCircle />
                            ) : report.state === "AD" ? (
                              <LoaderCircle className="animate-spin" />
                            ) : (
                              <LoaderCircle />
                            )}
                            Analyzing Data
                          </li>
                          <li>
                            {report.state === "DR" ? (
                              <CheckCircle />
                            ) : report.state === "GR" ? (
                              <LoaderCircle className="animate-spin" />
                            ) : (
                              <LoaderCircle />
                            )}
                            Generating Report
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
            )}
          </div>
          {latestReportsBox.length>0 ? (
            <div className="analysis-count">
              <Typography
                component={Link}
                to="/reports"
                fontWeight={500}
                color="#D4024B"
                paddingX={3}
              >
                {latestReportsBox.length} Analysis in Progress
              </Typography>
            </div>
          ):(
            <></>
          )}
        </div>
      </Box>

      <Box
        component="section"
        className="last-section main-paper col-span-2 lg:col-span-1 h-fit"
      >
        <div className="main-paper-title flex justify-between items-center mb-3">
          <Typography
            className="section-title-tpg"
            variant="subtitle1"
            fontWeight={600}
          >
            Last Reports
          </Typography>
          <Link className="no-underline" to="/reports">
            <OutlineBlackButton className="pointer-events-none" />
          </Link>
        </div>
        <div className="main-paper-body">
          <div className="analysis-boxes flex flex-col">
            {error && error}
            {loading ? (
              <>
                <ReportListSkeleton />
                <ReportListSkeleton />
                <ReportListSkeleton />
              </>
            ) : latestReportsList.length===0 ? (
              <Box className="w-full  flex flex-col items-center justify-center mt-[0.6rem]">
                <img className="object-contain w-[150px] sm:w-[250px]" src={emptyState} />
                <Typography fontSize={10} color={"rgba(0, 0, 0, 0.6)"}>
                No completed report is in progress, order your first report to see it here!
                </Typography>
              </Box>
            ) :  (
              latestReportsList
                .slice(-LATEST_REPORTS_LIST_COUNT)
                .reverse()
                .map((report) => (
                  <div key={report.id} className="analysis-box flex gap-4 py-4">
                    <div className="w-full flex gap-4 flex-row items-center justify-between">
                      <Box>
                        <Typography
                          className="report-title"
                          variant="subtitle2"
                          fontWeight={500}
                        >
                          {report.type.toLowerCase() === "ca"
                            ? `${report.name} Analysis`
                            : `${report.name} Followers list info`}
                        </Typography>
                        <Typography
                          color="primary"
                          fontSize={10}
                          fontWeight={600}
                          className=" ml-2"
                        >
                          {report.type.toLowerCase() === "ca"
                            ? `Influencers Selection`
                            : `Followers list info`}
                        </Typography>
                      </Box>
                      {/* <Button
                        variant="contained"
                        endIcon={<ChevronRight />}
                        className="bg-[#D4024B] text-white capitalize text-[13px] px-2 py-1 rounded-lg shadow-none"
                        disabled={report.state === "DR" ? false : true}
                      >
                        Download Report
                      </Button> */}

                      {report.type.toLowerCase() === "ca" ? (
                        report.state === "DR" ? (
                          <Button
                            component={Link}
                            to={API_URL + report.file}
                            variant="contained"
                            endIcon={<ChevronRight />}
                            size="small"
                            className="bg-[#D4024B] text-white capitalize px-4 h-10 rounded-3xl no-underline shadow-none lg:h-12"
                            disabled={
                              report.state === "DR" && !report.update_request
                                ? false
                                : true
                            }
                          >
                            Download Report
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            endIcon={<ChevronRight />}
                            size="small"
                            className="bg-[#D4024B] w-fit px-5 h-12 capitalize rounded-lg shadow-none mb-7"
                            disabled={report.state === "DR" ? false : true}
                          >
                            Download Report
                          </Button>
                        )
                      ) : (
                        <Button
                          disabled={report.state === "DR" ? false : true}
                          onClick={() => {
                            navigate("/followersListResult", {
                              state: {
                                report_id: report.id,
                                username: report.name,
                                userId: report.userid_list,
                              },
                            });
                          }}
                          variant="contained"
                          endIcon={<ChevronRight />}
                          size="small"
                          className="bg-[#D4024B] w-fit px-3 h-11 capitalize rounded-lg shadow-none mb-7"
                        >
                          Show report
                        </Button>
                      )}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      </Box>
    </main>
  );
};

export default Dashboard;
