import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const CircularProgressWithLabel = ({ value }) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        size="64px"
        variant="determinate"
        className="circular-progress-fg"
        color="inherit"
        value={value}
      />
      <CircularProgress
        size="64px"
        variant="determinate"
        value={100}
        className={"circular-progress-bg"}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="inherit"
          fontWeight={500}
          fontSize={16}
        >
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
