import { React, PropTypes, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  ButtonGroup,
  TextField,
  FormHelperText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import PaymentIcon from "@mui/icons-material/Payment";
import Package from "../../components/packages/package";
import { getPackages, stripe } from "../../apis";
import { stringify } from "postcss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PackageSkeleton from "../../components/skeleton/PackageSkeleton";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

// const packages = [
//   {
//     id: 1,
//     name: "package1",
//     description: "20% More Credit",
//     price: 20,
//     credit_amount: 15,
//   },
// ];

const Packages = ({ sidebarWidth }) => {
  const location = useLocation();
  const { pages, totalPrice, features, reportId, credit } =
    location.state || {};
  const [packages, setPackages] = useState([]);
  const [packageLoading, setPackageLoading] = useState(true);
  const [count, setCount] = useState();
  const [value, setValue] = useState("");
  const [inputDisable, setInputDisable] = useState(true);
  const [helperText, setHelperText] = useState("");

  const clearRadio = (event) => {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    document.title = "Credits - Binro";
    fetchPackages();
  }, []);
  useEffect(() => {
    if (credit) {
      setCount((parseFloat(  totalPrice - credit).toFixed(3))   );
    } else {
      setCount(0);
    }
  }, []);
  const fetchPackages = async () => {
    const response = await getPackages();

    if (response.status === 200) {
      setPackages(response.data);
      setPackageLoading(false);
      // setCount(response.data[0].price);
    }
  };
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };
  const userToken = getToken();
  const payHandler = async () => {
    let API_URL = process.env.REACT_APP_API_URL;
    if (count === 0) {
      setHelperText("Please select a package or enter a number.");
    } else {
      window.location.replace(
        `${API_URL}stripe/create-checkout-session/?price=${count}&user=${userToken}`
      );
    }
  };
  return (
    <div>
      <Box className="flex">
        <main className="grid grid-cols-1 gap-y-5 gap-x-5 w-full overflow-x-hidden p-5 pb-[100px]">
          <Box
            component="section"
            className="add-section main-paper p-4 flex flex-col"
          >
            <Typography fontSize={18} fontWeight={600} color={"black"} sx={{mt:7, ml:3, mb:-2}}>
              Choose a package or enter a number.
            </Typography>
            {packageLoading ? (
              <Box sx={{ display: "flex", gap: 0, flexDirection: "column" , pt:4}}>
                <PackageSkeleton />
                <PackageSkeleton />
                <PackageSkeleton />
              </Box>
            ) : (
              <RadioGroup defaultValue={value} value={value} sx={{ mt: 2 ,gap:-2}}>
                {packages.length ? (
                  packages.map((pack) => (
                    <FormControlLabel
                      onChange={() => {
                        setCount(pack.price);
                        setHelperText("");
                      }}
                      id="btn"
                      value={pack.price}
                      key={pack.id}
                      control={<Radio color="primary" onClick={clearRadio} />}
                      label={<Package package={pack} />}
                      sx={{
                        bgcolor: "#F7F7F7",
                        mx: 2,
                        my: 0.7,
                        p: 2.5,
                        borderRadius: 2,
                      }}
                    />
                  ))
                ) : (
                  <h2>No package is available.</h2>
                )}
              </RadioGroup>
            )}
            <Box>
              <Box
                helperText={helperText}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  height: "60px",
                  gap: 0,
                  mt: 3,
                  mb: 0,
                  mx: 2,
                }}
              >
                <Button
                  variant="outlined"
                  aria-label="reduce"
                  onClick={() => {
                    setCount(Math.max(count - 1, 0));
                    setValue("");
                    setHelperText("");
                  }}
                  sx={{
                    height: "100%",
                    border: "2px solid",
                    borderRadius: "10px",
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </Button>
                <TextField
                  disabled={value == "" ? false : true}
                  onChange={(e) => {
                    setCount(parseInt(e.target.value));
                    setHelperText("");

                    // var byValue = document.querySelectorAll(
                    //   `input[value=${toString(count)}]`
                    // );
                    // byValue.isChecked = true;
                    packages.map((p) => {
                      if (p.price == parseInt(e.target.value)) {
                        setValue(e.target.value);
                      }
                    });
                  }}
                  onClick={() => {
                    setValue("");
                  }}
                  fullWidth
                  type="number"
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      style: { textAlign: "center" },
                    },
                    min: 0,
                  }}
                  sx={{
                    mx: 2,
                    bgcolor: "#F7F7F7",
                    height: "100%",
                    border: 0,
                    textAlign: "center",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  variant="standard"
                  value={count}
                  autoComplete="off"
                />
                <Button
                  variant="outlined"
                  aria-label="increase"
                  onClick={() => {
                    setCount(count + 1);
                    setValue("");
                    setHelperText("");
                  }}
                  sx={{
                    height: "100%",
                    border: "2px solid",
                    borderRadius: "10px",
                  }}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </Box>
              <Box sx={{ display: "flex", pl: 12, mt: 0 }}>
                <FormHelperText>{helperText}</FormHelperText>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                height: "60px",
                gap: 0,
                my: 3,
                mx: 2,
                "& .MuiButton-contained": {
                  bgcolor: "#D20D4B",
                },
              }}
            >
              <Button
                type="submit"
                variant="contained"
                sx={{ width: "100%", height: "100%" }}
                startIcon={<PaymentIcon sx={{ color: "white" }} />}
                onClick={payHandler}
              >
                <Typography sx={{textTransform: 'capitalize'}} fontSize={18}>Pay ${count}</Typography>
              </Button>
            </Box>
            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "start",
              height: "60px",
              gap: 0,
              mb: 3,
              mx: 2,
            }}>
              <Typography fontSize={16} fontWeight={500} sx={{paddingLeft:"1rem"}}>
                For pay the price with currency,<Link target="_blank" className="font-semibold ml-3 text-[#D4024B] "  to="https://api.whatsapp.com/send?phone=09135465882">contact us in whatsapp</Link>. 
              </Typography>
            {/* <FloatingWhatsApp notification="false" buttonStyle={{width:'100%' , borderRadius:'5px', position:'initial'}} /> */}
            {/* <Button
            component={Link}
            to="https://api.whatsapp.com/send?phone=09135465882"
            variant="contained"
            sx={{ width: "100%", height: "100%" }}
>
            </Button> */}
            </Box>

          </Box>
        </main>
      </Box>
    </div>
  );
};

Packages.propTypes = {};

export default Packages;
