import FollowerListFollower from "../previews/FollowerListFollower";
import { useEffect } from "react";
import {
  Box,
  LinearProgress,
  Typography,
  Chip,
  Avatar,
  Skeleton,
  IconButton,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Button,
  Modal,
  Link,
} from "@mui/material";
const CustomModal = ({ page, open, setOpen }) => {
  console.log(page.info);
  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
  }, [open]);
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = "unset";
  };
  return (
    <Paper
      onClick={handleClose}
      sx={{
        height: "100%",
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        bgcolor: "rgba(0, 0, 0, 0.5)",
        padding: 0,
        zIndex: "10000",
        borderRadius:0
      }}
    >
      <Box className="bg-[white] p-6 rounded-xl m-6">
        <FollowerListFollower
          page={page.page}
          result="true"
        />
        <Box
          component="section"
          className="mt-4 add-section main-paper py-1 pb-6 flex flex-col"
        >
          <Box className="flex flex-col">
            <Box className="flex flex-row justify-start gap-2 items-center p-2">
              {!page.info.status ? (
                <Chip
                  label="Public"
                  variant="filled"
                  size="small"
                  sx={{ color: "#1F9254", bgcolor: "#EBF9F1" }}
                />
              ) : (
                <Chip
                  label="Private"
                  variant="filled"
                  size="small"
                  sx={{ color: "#A30D11", bgcolor: "#FBE7E8" }}
                />
              )}

              {page.info.is_business ? (
                <Chip
                  label="Business Page"
                  variant="filled"
                  size="small"
                  sx={{ color: "#1F9254", bgcolor: "#EBF9F1" }}
                />
              ) : (
                <Chip
                  label="Not Business Page"
                  variant="filled"
                  size="small"
                  sx={{ color: "#A30D11", bgcolor: "#FBE7E8" }}
                />
              )}
            </Box>
            <Box className="flex flex-col  justify-start items-start px-2 py-3">
              <Typography fontWeight={600} fontSize={16}>
                Bio Link:
              </Typography>
              <Box className="flex flex-col gap-2 items-center  pl-3">
                {page.info.bioLink.length
                  ? page.info.bioLink.map((link) => {
                      return <Box className="flex flex-row justify-between"><Link className="cursor-pointer" to={link.url}>{
                        link.title.lrngth>1 ? (link.title):(link.url)}</Link> </Box>;
                    })
                  : "-"}
              </Box>
            </Box>
            <Box className="flex flex-row justify-between items-start pl-2">
              <Box className="flex flex-col  items-start justify-between ">
                <Box className="flex flex-col items-start  justify-cenetr">
                  <Typography fontWeight={600}>Category:</Typography>
                  <Typography>{page.info.category}</Typography>
                </Box>

                <Box className="flex flex-col items-start  justify-cenetr ">
                  <Typography fontWeight={600} fontSize={16}>
                    User Id:
                  </Typography>
                  <Typography fontSize={14}>#{page.info.userId}</Typography>
                </Box>
              </Box>
              <Box className="flex flex-col  items-start justify-between pr-10 ">
                <Box className="flex flex-col items-start  justify-cenetr ">
                  <Typography fontWeight={600}>Mobile:</Typography>
                  <Typography>{page.info.mobile}</Typography>
                </Box>
                <Box className="flex flex-col  items-start  justify-cenetr">
                  <Typography fontWeight={600} fontSize={16}>
                    Email:
                  </Typography>
                  <Typography fontSize={14}>{page.info.email}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default CustomModal;
