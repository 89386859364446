import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, Divider } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link, useNavigate } from "react-router-dom";

const Failed = () => {
  return (
    <Box className="app flex justify-center items-center w-full min-h-screen h-max bg-[#102132] sm:p-12">
      <Box className="bg-white space-y-12 max-w-2xl max-h-full mx-auto flex flex-col py-24 px-24 flex-wrap justify-between items-center w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)]  sm:rounded-xl sm:min-h-fit">
        <Box className="flex space-y-3 flex-col justify-between items-center ">
          <Box className="bg-[#F9E2E9] rounded-full w-20 h-20 flex justify-center items-center">
            <CancelIcon className="w-10 h-10" sx={{ color: "#D10D4B" }} />
          </Box>
          <Typography
            varient="h1"
            fontWeight={600}
            className="text-[#D10D4B] text-2xl"
          >
            Payment Failed!
          </Typography>
        </Box>
        <Divider className="w-11/12" />

        <Box className="flex flex-col justify-center items-center">
          <Link
            className="text-[#D4024B] font-semibold ml-3 cursor-pointer"
            to="/dashboard"
          >
            Back to dashboard{" "}
          </Link>
          <a
            className="text-[#D4024B] font-semibold ml-3"
            href="http://binro.io/support"
          >
            Contact us
          </a>
        </Box>
        {/* <Divider className="w-11/12" />
        <Box className="space-y-3.5 flex flex-col justify-between items-center max-w-5xl mx-auto w-full ">
          <Box className="flex flew-row justify-between items-center  max-w-5xl mx-auto w-full">
            <Typography
              variant="body2"
              fontWeight={600}
              className="text-[#666666]"
            >
              Order ID
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              className="text-[#D10D4B]"
            >
              0000085
            </Typography>
          </Box>
          <Box className="flex flew-row justify-between items-center  max-w-5xl mx-auto w-full">
            <Typography
              variant="body2"
              fontWeight={600}
              className="text-[#666666]"
            >
              Amount
            </Typography>
            <Typography
              variant="body2"
              fontWeight={600}
              className="text-[#D10D4B]"
            >
              IDR 1,000,000
            </Typography>
          </Box>
        </Box> */}
      </Box>

      {/* <ToastContainer /> */}
    </Box>
  );
};
export default Failed;
