import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import ShowHidePassword from "../../components/inputs/ShowHidePassword";
import logo from "../../assets/images/logo.svg";
import sign_in_vector from "../../assets/images/sign-in-vector.jpg";
import { validate } from "../../components/validate";
// import { notify } from "../../components/notify/toast";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./forgot-password";
import { login } from "../../apis";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import googleIcon from "../../assets/images/google.png";

import axios from "axios";

const SignIn = ({ setToken }) => {
  const userRef = useRef(null);
  const pwdRef = useRef(null);

  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setErrors(validate(data, "sign-in"));
  }, [data, touched]);

  useEffect(() => {
    document.title = "Sign In - Binro";
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await axios.post(
        "https://api.binro.io/rest-auth/google/",
        {
          access_token: tokenResponse.access_token,
        }
      );
      if (response.status === 200) {
        sessionStorage.setItem("token", JSON.stringify(response.data.key));
        console.log(response.data.key);

        window.location.reload();
      }
    },
    // flow: 'implicit', // implicit is the default
  });

  const changeHandler = (event) => {
    if (event.target.name === "is_accepted") {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
      setServerErrors({ ...serverErrors, [event.target.name]: undefined });
    }
  };
  // Event handler for input focus
  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  // Event handler for form submission
  const submitHandler = async (event) => {
    event.preventDefault();
    // Checking if there are no errors before submitting the form
    if (!Object.keys(errors).length) {
      setIsLoading(true);
      // Sending login request to the server
      try {
        const response = await login({
          username: userRef.current.value,
          password: pwdRef.current.value,
        });

        // console.log(response.status);
        if (response.status === 200) {
          // Setting the authentication token and reloading the page
          setToken(response.data.token);
          window.location.reload();

          // console.log("200", response);
        } else if (response.status === 400) {
          setErrors({
            username: "The username or password is incorrect",
          });
        } else if (response.status >= 500) {
          setErrors({
            username: "Server Error! Please try again later",
          });
        }
        // console.log(response);
      } catch (error) {
        if (error.response.status === 400) {
          setServerErrors({
            username: "The username or password is incorrect",
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setTouched({
        username: true,
        password: true,
      });
    }
  };
  // Rendering the sign-in form
  return (
    <Box className="app flex justify-center items-center w-full min-h-screen bg-[#102132] sm:p-12">
      <Box className="bg-white max-w-5xl mx-auto flex flex-wrap items-stretch w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)] p-4 sm:rounded-xl sm:min-h-fit">
        <Box className="form-logo flex-1 min-w-full self-start flex justify-between items-center p-4 pb-0">
          <Box className="logo w-20">
            <img src={logo} alt="binro logo" />
          </Box>
        </Box>
        <Box className="form-vector w-1/4 hidden md:w-[450px] lg:w-[514px] md:flex">
          <Box className="flex items-center justify-center p-8">
            <img className="object-contain" src={sign_in_vector} alt="" />
          </Box>
        </Box>
        <Box className="account-form flex-1">
          <Box className="flex flex-col justify-between h-full gap-4 p-5">
            <Box className="account-form-header hidden justify-between items-center md:flex"></Box>
            <Box className="account-form-body max-w-sm mx-auto">
              <Box className="form-heading mb-8">
                <Typography variant="h5" fontWeight={600}>
                  Login
                </Typography>
                {/* <Typography variant="caption">Login to your account</Typography> */}
              </Box>
              <Box className="form-inputs">
                <form onSubmit={submitHandler}>
                  <Box className="grid grid-cols-2 gap-5 ">
                    <TextField
                      inputRef={userRef}
                      type="text"
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.username}
                      className="col-span-2"
                      name="username"
                      label="Username"
                      variant="outlined"
                      size="small"
                      helperText={
                        (errors.username &&
                          touched.username &&
                          errors.username) ||
                        (serverErrors.username !== undefined &&
                          serverErrors.username)
                      }
                      autoComplete="off"
                      required
                    />
                    <ShowHidePassword
                      forwardedRef={pwdRef}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.password}
                      className="col-span-2 capitalize"
                      name="password"
                      label="Password"
                      size="small"
                      helperText={
                        errors.password && touched.password && errors.password
                      }
                    />
                  </Box>
                  {/* <FormGroup className="mb-3">
                    <FormControlLabel
                      control={<Checkbox name="is_accepted" size="small" />}
                      label={
                        <Typography variant="caption" fontSize={14}>
                          remember me
                        </Typography>
                      }
                    />
                  </FormGroup> */}
                  <Box className="flex justify-end items-center mb-3">
                   
                    <Box>
                      <Typography variant="caption" fontSize={10}>
                        <Link
                          className="text-[#D4024B] font-semibold"
                          to="/forgot-password"
                        >
                          Forgot password
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                      type="submit"
                      variant="contained"
                      className="bg-[#D4024B] capitalize shadow-none w-full"
                      endIcon={
                        isLoading && (
                          <CircularProgress
                            sx={{
                              circle: {
                                stroke: "#fff",
                              },
                            }}
                            size={22}
                          />
                        )
                      }
                      disabled={isLoading && true}
                    >
                      Sign In
                    </Button>
                </form>
                <Divider textAlign="center" className="mt-5">
                  <Typography fontSize={10} color="#666666">
                    Or
                  </Typography>
                </Divider>
                <Button
                  onClick={googleLogin}
                  variant="outlined"
                  className="capitalize w-full shadow-none mt-5"
                  startIcon={
                    <Box className="logo w-5">
                      <img src={googleIcon} alt="google logo" />
                    </Box>
                  }
                >
                  Log in with Google
                </Button>
              </Box>
            </Box>
            <Box className="account-form-footer text-center">
              <Box>
                <Typography variant="caption" fontSize={14}>
                  Haven't created an account yet?
                  <Link
                    className="font-semibold ml-3 text-[#D4024B]"
                    to="/sign-up"
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default SignIn;
