import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ShowHidePassword from "../../components/inputs/ShowHidePassword";
import logo from "../../assets/images/logo.svg";
import sign_up from "../../assets/images/sign-up.svg";
import appsumoLogo from "../../assets/images/appsumo-logo.svg";
import { validate } from "../../components/validate";
import { notify } from "../../components/notify/toast";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { signUp, googleLogin } from "../../apis";
import { Add } from "@mui/icons-material";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import axios from "axios";
import googleIcon from "../../assets/images/google.png";

const SignUp = ({ setToken }) => {
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    // credit_message: "",
    // is_accepted: false,
  });
  //states

  const [errors, setErrors] = useState({});
  const [serverErrors, setServerErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [country, setCountry] = useState("us");
  const [phone, setPhone] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [coupon, setCoupon] = useState("");
  const [coupons, setCoupons] = useState([]);
  const navigate = useNavigate();

  // const first_name = useRef(null);
  // const last_name = useRef(null);
  const username = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const confirm_password = useRef(null);
  // const phone_number = useRef(null);
  const couponRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  // This useEffect hook is used to update the document title to "Sign Up - Binro".
  useEffect(() => {
    setErrors(validate(data, "sign-up"));
  }, [data, touched, serverErrors]);

  useEffect(() => {
    document.title = "Sign Up - Binro";
  }, []);
  // This function is a change handler that is triggered when an event (e.g., user input) occurs.
  const changeHandler = (event) => {
    if (event.target.name === "is_accepted") {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else if (event.target.name === "coupon") {
      setCoupon(event.target.value);
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
      setServerErrors({ ...serverErrors, [event.target.name]: undefined });
    }
  };

  const focusHandler = (event) => {
    setTouched({ ...touched, [event.target.name]: true });
  };
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await axios.post(
        "https://api.binro.io/rest-auth/google/",
        {
          access_token: tokenResponse.access_token,
        }
      );
      if (response.status === 200) {
        sessionStorage.setItem("token", JSON.stringify(response.data.key));
        console.log(response.data.key);

        window.location.reload();
      }
    },
    // flow: 'implicit', // implicit is the default
  });
  // This function is a submit handler that is triggered when a form is submitted.

  const submitHandler = async (event) => {
    event.preventDefault();
    googleLogin();
    // Checks if the 'errors' object does not have any keys.
    if (!Object.keys(errors).length) {
      setIsLoading(true);

      try {
        // Performs an asynchronous sign up operation with the given user input data.

        const response = await signUp({
          // first_name: first_name.current.value,
          // last_name: last_name.current.value,
          username: username.current.value,
          email: email.current.value,
          password: password.current.value,
          confirm_password: confirm_password.current.value,
          // phone_number: rawPhone,
          // country: country,
          // ...(coupons.join(",").trim() !== "" && {
          //   credit_referral_source: coupons.join(","),
          // }),
        });
        // If the sign up is successful and the response status is 201, the user is redirected to the sign-in page.
        if (response.status === 201) {
          notify("Your account successfully created!", "success");
          navigate("/sign-in");
        }
      } catch (error) {
        if (error.response.status === 400) {
          // If the response status is 400, it means that there were validation errors.
          setServerErrors(error.response.data);
        } else if (error.response.status >= 500) {
          // If the response status is greater than or equal to 500, it indicates a server error.

          notify("Server Error!", "error");
        }
        // Handles any errors that occurred during the sign up operation.
        console.log(error.response);
        if (error.response.data !== undefined) {
          setServerErrors(error.response.data);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setTouched({
        // first_name: true,
        // last_name: true,
        username: true,
        email: true,
        password: true,
        confirm_password: true,
        // phone_number: true,
        // credit_message: true,
        // is_accepted: true,
      });
    }
  };

  // const addCoupon = () => {
  //   if (coupons.length < 10 && coupon.trim() !== "") {
  //     if (!coupons.includes(coupon.trim())) {
  //       setCoupons((oldArray) => [...oldArray, coupon]);
  //     }
  //   }
  //   setCoupon("");
  // };

  const handleDelete = (chipToDelete) => () => {
    setCoupons((chips) => chips.filter((chip) => chip !== chipToDelete));
  };
  // Rendering the sign-up form
  return (
    <Box className="app flex justify-center items-center w-full min-h-screen bg-[#102132] sm:p-12">
      <Box className="bg-white max-w-5xl mx-auto flex flex-wrap items-stretch w-full min-h-screen shadow-[0_4px_20px_rgba(0,0,0,0.1)] p-4 sm:rounded-xl sm:min-h-fit">
        <Box className="form-logo flex-1 min-w-full self-start flex justify-between items-center p-4 pb-0">
          <Box className="logo w-20">
            <img src={logo} alt="binro logo" />
          </Box>
        </Box>
        <Box className="account-form flex-1">
          <Box className="flex flex-col justify-between h-full gap-4 p-5">
            <Box className="account-form-header hidden justify-between items-center md:flex"></Box>
            <Box className="account-form-body max-w-sm mx-auto">
              <Box className="form-heading mb-8">
                <Typography variant="h5" fontWeight={600}>
                  Create New Account
                </Typography>
                {/* <Typography variant="caption">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Natus, vel.
                </Typography> */}
              </Box>
              <Box className="form-inputs">
                <form
                  onSubmit={submitHandler}
                  className="flex flex-col justify-center align-center"
                >
                  <Button
                    onClick={googleLogin}
                    variant="outlined"
                    className="capitalize w-full shadow-none mb-5"
                    startIcon={
                      <Box className="logo w-5">
                        <img src={googleIcon} alt="google logo" />
                      </Box>
                    }
                  >
                    Sign Up with Google
                  </Button>
                  <Divider textAlign="center" className="mb-5">
                    <Typography fontSize={10} color="#666666">
                      Or
                    </Typography>
                  </Divider>
                  <Box className="grid grid-cols-2 gap-5 mb-3 ">
                    {/* <TextField
                      inputRef={first_name}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.first_name}
                      name="first_name"
                      label="First Name"
                      variant="outlined"
                      size="small"
                      helperText={
                        errors.first_name &&
                        touched.first_name &&
                        errors.first_name
                      }
                      required
                    />
                    <TextField
                      inputRef={last_name}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.last_name}
                      name="last_name"
                      label="Last Name"
                      variant="outlined"
                      size="small"
                      helperText={
                        errors.last_name &&
                        touched.last_name &&
                        errors.last_name
                      }
                      required
                    /> */}

                    <TextField
                      inputRef={username}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.username}
                      className="col-span-2"
                      name="username"
                      label="Userame"
                      variant="outlined"
                      size="small"
                      helperText={
                        (errors.username &&
                          touched.username &&
                          errors.username) ||
                        (serverErrors.username !== undefined &&
                          serverErrors.username)
                      }
                      required
                    />
                    <TextField
                      inputRef={email}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      value={data.email}
                      className="col-span-2"
                      name="email"
                      label="Email"
                      variant="outlined"
                      size="small"
                      helperText={
                        (errors.email && touched.email && errors.email) ||
                        (serverErrors.email !== undefined && serverErrors.email)
                      }
                      required
                    />
                    <Box className="flex flex-row col-span-2 gap-2">
                      <ShowHidePassword
                        forwardedRef={password}
                        onChange={changeHandler}
                        onFocus={focusHandler}
                        value={data.password}
                        className="col-span-2 capitalize"
                        name="password"
                        label="Password"
                        size="small"
                        helperText={
                          (errors.password &&
                            touched.password &&
                            errors.password) ||
                          (serverErrors.password !== undefined &&
                            serverErrors.password)
                        }
                      />
                      <ShowHidePassword
                        forwardedRef={confirm_password}
                        onChange={changeHandler}
                        onFocus={focusHandler}
                        value={data.confirm_password}
                        className="col-span-2 capitalize"
                        name="confirm_password"
                        label="Confirm Password"
                        size="small"
                        helperText={
                          (errors.confirm_password &&
                            touched.confirm_password &&
                            errors.confirm_password) ||
                          (serverErrors.confirm_password !== undefined &&
                            serverErrors.confirm_password)
                        }
                      />
                    </Box>
                    <Box className="col-span-2">
                      {/* <PhoneInput
                        inputProps={{
                          ref: phone_number,
                          name: "phone_number",
                          required: true,
                        }}
                        specialLabel="Phone Number *"
                        value={phone}
                        onChange={(phone, data) => {
                          setPhone(phone);
                          setRawPhone(`0${phone.slice(data.dialCode.length)}`);
                          setCountry(data.name);
                          setServerErrors({
                            ...serverErrors,
                            phone_number: "",
                          });
                        }}
                        enableSearch
                        name="number"
                        className="w-full flex"
                        country={country}
                      /> */}
                      {errors.phone_number && touched.phone_number && (
                        <FormHelperText>{errors.phone_number}</FormHelperText>
                      )}
                      <FormHelperText>
                        {serverErrors.phone_number !== undefined &&
                          serverErrors.phone_number}
                      </FormHelperText>
                    </Box>
                    {coupons.length ? (
                      <Box className="col-span-2 grid gap-y-3">
                        {coupons.map((coupon) => (
                          <Chip
                            className="w-full flex justify-between items-center"
                            onDelete={handleDelete(coupon)}
                            key={coupon}
                            label={coupon}
                          />
                        ))}
                      </Box>
                    ) : (
                      ""
                    )}
                    {/* <TextField
                      inputRef={couponRef}
                      value={coupon}
                      onChange={changeHandler}
                      onFocus={focusHandler}
                      onBlur={addCoupon}
                      className="col-span-2"
                      name="coupon"
                      label="Appsumo purchase code (Optional)"
                      placeholder="Appsumo purchase code (Optional)"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              disabled={coupons.length >= 10}
                              className="bg-[#D4024B]/10"
                              size="small"
                              edge="end"
                              onClick={addCoupon}
                            >
                              <Add />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        ".MuiInputAdornment-root, .MuiButtonBase-root": {
                          minHeight: "100%",
                          minWidth: "40px",
                        },
                        " .MuiButtonBase-root": {
                          borderRadius: "0 4px 4px 0",
                        },
                        ".MuiInputBase-root": {
                          pr: 0,
                        },
                        input: {
                          pr: "14px",
                        },
                      }}
                      disabled={coupons.length >= 10}
                      helperText={
                        serverErrors.credit_message !== undefined &&
                        serverErrors.credit_message &&
                        serverErrors.credit_message
                      }
                    /> */}
                  </Box>
                  {/* <Box className="flex items-center p-2.5 mt-4 text-[#D4024B] font-medium border border-[#D4024B] text-center rounded-lg bg-[#D4024B1A] mx-auto md:hidden">
                    <Typography fontSize={14}>
                      Buy a lifetime account on
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://appsumo.com/products/binro?utm_source=partner-link&utm_medium=referral&utm_campaign=partner-182516"
                        className="font-bold mx-2 underline"
                      >
                        Appsumo
                      </a>
                      with special features and{" "}
                      <span className="font-bold">69% discount</span> for every
                      Instagram page analysis
                    </Typography>
                  </Box> */}

                  {/* <FormGroup className="mb-3">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_accepted"
                          onChange={changeHandler}
                          onFocus={focusHandler}
                          value={data.is_accepted}
                          size="small"
                          required
                        />
                      }
                      label={
                        <Typography variant="caption" fontSize={14}>
                          I agree all statements in Terms of service
                        </Typography>
                      }
                    />
                    {errors.is_accepted && touched.is_accepted && (
                      <FormHelperText className="mt-0">
                        {errors.is_accepted}
                      </FormHelperText>
                    )}
                  </FormGroup> */}
                  <Button
                    type="submit"
                    variant="contained"
                    className="bg-[#D4024B] capitalize shadow-none mr-20 ml-20"
                    endIcon={
                      isLoading && (
                        <CircularProgress
                          sx={{
                            circle: {
                              stroke: "#fff",
                            },
                          }}
                          size={22}
                        />
                      )
                    }
                    disabled={isLoading}
                  >
                    Create Account
                  </Button>
                </form>
              </Box>
            </Box>
            <Box className="account-form-footer text-center">
              <Typography variant="caption" fontSize={14}>
                Already have an account?
                <Link className="font-semibold ml-3" to="/">
                  Sign In
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="form-vector w-1/4 hidden md:w-[450px] lg:w-[514px] md:flex flex-col justify-center">
          <Box className="flex items-center justify-center p-8">
            <img className="object-contain" src={sign_up} alt="" />
          </Box>
          {/* <Box className="flex items-center p-2.5 mt-4 text-[#D4024B] font-medium border border-[#D4024B] text-center rounded-lg bg-[#D4024B1A] mx-auto md:max-w-[80%]">
            <Typography fontSize={14}>
              Buy a lifetime account on
              <a
                target="_blank"
                rel="noreferrer"
                href="https://appsumo.com/products/binro?utm_source=partner-link&utm_medium=referral&utm_campaign=partner-182516"
                className="font-bold mx-2 underline"
              >
                Appsumo
              </a>
              with special features and
              <span className="font-bold">69% discount</span> for every
              Instagram page analysis
            </Typography>
          </Box> */}
        </Box>
      </Box>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default SignUp;
