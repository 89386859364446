import React from "react";

const EyeClose = ({ className, size = 24 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <symbol
          id="visibilityOff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M11 16.942a8.537 8.537 0 01-1.92-.452l-.67 1.842-1.88-.684.743-2.042c-.4-.25-.795-.534-1.182-.85L4.8 16.292 3.27 15.006l1.367-1.63a18.86 18.86 0 01-1.45-1.794l1.626-1.164C7.028 13.509 9.413 15 12 15c2.587 0 4.972-1.49 7.187-4.582l1.626 1.164a18.85 18.85 0 01-1.45 1.794l1.367 1.63-1.532 1.286-1.289-1.536c-.396.323-.8.613-1.21.867l.737 2.025-1.88.684-.666-1.831a8.535 8.535 0 01-1.89.441V19h-2v-2.058z"></path>
        </symbol>
      </defs>
      <use href="#visibilityOff"></use>
    </svg>
  );
};

export { EyeClose };
