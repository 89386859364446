import React from "react";
import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";

const OutlineBlackButton = () => {
  return (
    <Button
      variant="outlined"
      className="text-[#1C1C1C] border-[#E8E8E8] text-[13px] capitalize shadow-[0_1px_1px_rgba(0,0,0,0.06)] px-2 py-1 rounded-lg"
      endIcon={<ChevronRight className="fill-[#969696]" />}
    >
      See All
    </Button>
  );
};

export default OutlineBlackButton;
