import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import not_found from "../assets/images/404-not-found.png";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    // <h2>asrfgsdfgsdfg</h2>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "90vh",
      }}
    >
      <Container maxWidth="md">
        <Box className="grid grid-cols-2 items-center">
          <Box className="col-span-2 lg:col-span-1 lg:order-2">
            <img
              className="w-full max-w-lg lg:max-w-full mx-auto"
              src={not_found}
              alt="404 not found"
            />
          </Box>
          <Box className="col-span-2 lg:col-span-1 lg:order-1">
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
              The page you’re looking for doesn’t exist.
            </Typography>
            <Link to="/">
              <Button
                className="bg-[#D4024B] shadow-none normal-case mt-5"
                variant="contained"
              >
                Back Home
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default NoMatch;
