import React from "react";

const ChevronDown = ({ className, size = 10 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 10 7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.412893 2.38145L4.01989 5.98845C4.56303 6.53159 5.44041 6.53159 5.98354 5.98845L9.59054 2.38145C10.4679 1.50408 9.84122 0 8.60175 0H1.38776C0.148287 0 -0.464485 1.50408 0.412893 2.38145Z" />
    </svg>
  );
};

export { ChevronDown };
