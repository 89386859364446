import React from "react";

const ChartNetwork = ({ className, size = 22 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 7C22.2305 7 23.25 6.01562 23.25 4.75C23.25 3.51953 22.2305 2.5 21 2.5C19.7344 2.5 18.75 3.51953 18.75 4.75C18.75 6.01562 19.7344 7 21 7ZM3 8.6875C1.73438 8.6875 0.75 9.70703 0.75 10.9375C0.75 12.2031 1.73438 13.1875 3 13.1875C4.23047 13.1875 5.25 12.2031 5.25 10.9375C5.25 9.70703 4.23047 8.6875 3 8.6875ZM18.7852 7.38672L18.1172 6.50781L16.4297 7.77344L17.0977 8.65234L18.7852 7.38672ZM21 13.75C20.4727 13.75 20.0156 13.9258 19.6289 14.207L16.2539 12.2031C16.3945 11.8164 16.5 11.3945 16.5 10.9375C16.5 8.75781 14.707 7 12.5625 7C12.2461 7 11.9648 7.03516 11.6836 7.10547L10.2422 4.04688C10.6289 3.625 10.875 3.09766 10.875 2.5C10.875 1.26953 9.85547 0.25 8.625 0.25C7.35938 0.25 6.375 1.26953 6.375 2.5C6.375 3.76562 7.35938 4.75 8.625 4.75C8.66016 4.75 8.69531 4.75 8.73047 4.75L10.1719 7.84375C9.22266 8.54688 8.625 9.67188 8.625 10.9375C8.625 13.1172 10.3828 14.875 12.5625 14.875C13.6875 14.875 14.6719 14.418 15.4102 13.6445L18.7852 15.6836C18.75 15.7891 18.75 15.8945 18.75 16C18.75 17.2656 19.7344 18.25 21 18.25C22.2305 18.25 23.25 17.2656 23.25 16C23.25 14.7695 22.2305 13.75 21 13.75ZM12.5625 12.625C11.6133 12.625 10.875 11.8867 10.875 10.9375C10.875 10.0234 11.6133 9.25 12.5625 9.25C13.4766 9.25 14.25 10.0234 14.25 10.9375C14.25 11.8867 13.4766 12.625 12.5625 12.625ZM6.09375 11.5H7.78125V10.375H6.09375V11.5Z" />
    </svg>
  );
};

export { ChartNetwork };
