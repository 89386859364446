import React from "react";

const EyeOpen = ({ className, size = 24 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <symbol
          id="visibilityOn"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M12 18c3.304 0 6.252-1.843 8.813-5.418L21.23 12l-.417-.582C18.252 7.843 15.303 6 12 6c-3.304 0-6.252 1.843-8.813 5.418L2.77 12l.417.582C5.748 16.157 8.697 18 12 18zm0-10c2.42 0 4.662 1.303 6.753 4-2.091 2.697-4.334 4-6.753 4-2.333 0-4.501-1.212-6.529-3.716L5.247 12C7.338 9.303 9.58 8 12 8zm0 7a3 3 0 100-6 3 3 0 000 6z"></path>
        </symbol>
      </defs>
      <use href="#visibilityOn"></use>
    </svg>
  );
};

export { EyeOpen };
