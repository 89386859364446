import React from "react";

const Gem = ({ className, size = 22 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.9258 0.25H15.1133L17.5391 5.875H21.125L17.9258 0.25ZM13.4258 0.25H8.53906L6.11328 5.875H15.8516L13.4258 0.25ZM4.03906 0.25L0.875 5.875H4.42578L6.85156 0.25H4.03906ZM0.875 7L8.53906 15.9648C8.64453 16.0703 8.78516 15.9648 8.71484 15.8594L4.39062 7H0.875ZM6.07812 7L10.8945 18.2148C10.9297 18.2852 11.0352 18.2852 11.0703 18.2148L15.8867 7H6.07812ZM13.25 15.8594C13.1797 15.9648 13.3555 16.0703 13.4258 15.9648L21.125 7H17.5742L13.25 15.8594Z" />
    </svg>
  );
};

export { Gem };
