import React, { useState, useContext, useEffect } from "react";
import { CreditCard, Help, PowerSettingsNew } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
  Chip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { ChevronDown } from "../../icons/ChevronDown";
import { red } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../../App";
import AddIcon from "@mui/icons-material/Add";
import { stringify } from "json5";
const Header = ({ sidebarWidth, toggler }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [prfName, setPrfName] = useState("");
  const UserInfo = useContext(UserInfoContext);

  const { data } = UserInfo;
  const { first_name, last_name, username, credit, referral_source } = data;

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    if (first_name === "" || last_name === "") {
      setPrfName(username);
    } else {
      setPrfName(`${first_name} ${last_name}`);
    }
  }, [UserInfo, first_name, last_name, username]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    // try {
    //   const response = await logout();

    //   if (response.status === 201) {
    //     sessionStorage.removeItem("token");
    //     navigate("/");
    //     navigate(0);
    //   } else if (response.status >= 400) {
    //     notify("Logout Error!", "error");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    sessionStorage.removeItem("token");
    navigate("/");
    navigate(0);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className="bg-white shadow-[0_4px_20px_rgba(0,0,0,0.1)] sm:min-h-[60px]"
        sx={{
          width: { md: `calc(100% - ${sidebarWidth}px)` },
          ml: { md: `${sidebarWidth}px` },
        }}
      >
        <Toolbar className="justify-between text-black md:justify-end">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggler}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box className="flex items-center gap-x-4">
            {/* <Box>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge
                  badgeContent={2}
                  color="error"
                  sx={{
                    ".MuiBadge-badge": {
                      minWidth: "17px",
                      height: "17px",
                      fontSize: "10px",
                      top: "-4px",
                      left: "10px",
                      right: "auto",
                      transform: "none",
                    },
                  }}
                >
                  <Bell className="fill-gray-400" />
                </Badge>
              </IconButton>
            </Box> */}

            <Box>
              {/* {!UserInfo.loading && (
                
              )} */}

              {UserInfo.loading ? (
                <Box className="flex items-center gap-x-2">
                  <Skeleton className="w-8 h-8" variant="circular" />
                  <Skeleton
                    className="w-44 h-4 rounded-md"
                    variant="rectangular"
                  />
                </Box>
              ) : (
                <Box className="flex items-center gap-x-2">
                  <Chip
                    variant="outlined"
                    sx={{ py: 1 }}
                    className="sm:py-[18px]"
                    label={
                      <Typography className="text-xs sm:text-base">
                        ${parseFloat(credit.toFixed(6))} Credit
                      </Typography>
                    }
                    icon={
                      <IconButton size="small" component={Link} to="/packages">
                        <AddIcon
                          color="primary"
                          sx={{ bgcolor: "#FFEAF1", borderRadius: "50%" }}
                        />
                      </IconButton>
                    }
                  />
                  <Button
                    onClick={handleClick}
                    size="small"
                    className="text-black px-2 normal-case text-base"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    endIcon={<ChevronDown className="fill-gray-400" />}
                    startIcon={
                      <Avatar className="w-8 h-8 text-[#D4024B] bg-[#D4024B1A] text-sm">
                        {UserInfo.error ? "!" : prfName.charAt(0)}
                      </Avatar>
                    }
                    sx={{
                      ".MuiButton-endIcon": {
                        marginLeft: "15px",
                      },
                    }}
                  >
                    {UserInfo.error ? UserInfo.error : prfName}
                  </Button>
                </Box>
              )}

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleClose}>
                  <Link to="/settings">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Avatar /> {username}
                    </Box>
                  </Link>
                </MenuItem>

                <Divider />
                <MenuItem
                  sx={{
                    color: red[500],
                    fill: red[500],
                  }}
                  onClick={() => logOut()}
                >
                  <ListItemIcon>
                    <PowerSettingsNew
                      sx={{
                        color: red[500],
                      }}
                      fontSize="small"
                    />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
            {UserInfo.loading ? (
              <Skeleton
                variant="rectangular"
                className="w-[117px] h-6 px-[6px] py-[5px] rounded-md"
              ></Skeleton>
            ) : (
              !!referral_source &&
              referral_source.toLowerCase() === "appsumo" && (
                <Box className="flex items-center tracking-wide gap-2 text-[10px] uppercase text-[#026100] bg-[#00FF29]/25 font-semibold px-[6px] py-[5px] rounded-md">
                  <span className="hidden sm:inline">Lifetime access</span>
                  <Tooltip
                    arrow
                    title={
                      <ul className="list-disc">
                        <li>
                          - Lifetime access to unlimited free reanalysis for all
                          pages (once per month per page)
                        </li>
                        <li>- Early use of new features</li>
                      </ul>
                    }
                  >
                    <Help className="w-4 h-4 text-[#026100] cursor-pointer" />
                  </Tooltip>
                </Box>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
