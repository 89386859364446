import React from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";

const PackageSkeleton = () => {
  return (
    <Box className=" flex h-100 py-4">
    <Box className="w-full  flex items-center justify-center gap-4">
      <Skeleton width={40} height={40} variant="circular" className="" />
      <Skeleton
        className="rounded-lg"
        width='80%'
        height={35}
        variant="rectangular"
      />
    </Box>
  </Box>
  );
};

export default PackageSkeleton;
