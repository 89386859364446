import React, { useEffect, useRef, useState } from "react";
// import useToken from "../../useToken";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Check } from "@mui/icons-material";
import { CreditCard } from "../../components/icons/CreditCard";
import { checkCode, order, orders, payments as getPayments } from "../../apis";
import { notify } from "../../components/notify/toast";

const Payment = ({ sidebarWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pages, totalPrice, features, reportId, credit } = location.state;

  const [payments, setPayments] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(true);
  const [paymentsError, setPaymentsError] = useState("");
  const [payment, setPayment] = useState("");
  const [finalPrice, setFinalPrice] = useState(totalPrice);

  const [couponLoading, setCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [useCoupon, setUseCoupon] = useState(0);
  const [useCode, setUseCode] = useState(false);

  const [couponCode, setCouponCode] = useState("");

  const [payLoading, setPayLoading] = useState(false);
  const [payError, setPayError] = useState("");

  const couponInputRef = useRef(null);

  const fetchOrders = async () => {
    const response = await orders();

    // console.log(response.data);
  };

  const checkCoupon = async (event) => {
    event.preventDefault();

    setCouponLoading(true);
    setCouponError(undefined);

    try {
      // Send a request to check the coupon code

      const response = await checkCode({
        code: couponInputRef.current.value,
      });
      if (response.status === 200) {
        if (Number(response.data.message) - pages.length >= 0) {
          setFinalPrice(0);
          notify("The purchase code has been applied! ✅", "success");
          setUseCoupon(Number(response.data.message));
          setCouponCode(couponInputRef.current.value);
          setUseCode(true);
        } else {
          setCouponError(
            `The selected token has less capacity than the number of pages! (capacity: ${response.data.message})`
          );
        }
      } else if (response.status >= 400) {
        setCouponError("This discount code is not available!");
      }
    } catch (error) {
      // Catch any errors that occur during the try block and show an error message
      setCouponError("This discount code is not available!");
    } finally {
      setCouponLoading(false);
    }
  };

  const payHandler = async () => {
    setPayLoading(true);
    setPayError(undefined);

    try {
      const response = await order({
        report: reportId,
        price: credit > 0 && pages.length <= credit ? 0 : totalPrice,
        payment_method: payment.id,
        ...(couponCode.trim() !== "" && {
          discount_code: couponCode,
        }),
      });

      // console.log(response);

      if (response.status === 201) {
        navigate("/influencers-selection/result", {
          state: {
            status: response.status,
          },
        });

        localStorage.removeItem("pages");
      } else if (response.status >= 400) {
        setPayError("An error occured");
      }

      // console.log(response);
    } catch (error) {
      // console.log(error);
      // setPayError("An error occured");
    } finally {
      setPayLoading(false);
    }
  };
  // Function to fetch payments data from the server
  const fetchPayments = async () => {
    setPaymentsError(undefined);
    try {
      const response = await getPayments();
      if (response.status === 200) {
        setPayments(response.data);
        setPayment(response.data[0]);
      } else if (response.status >= 400) {
        // console.log(response.status);
        setPaymentsError("Payments Error!");
      }
    } catch (error) {
      setPaymentsError("Payments Error!");
    } finally {
      setPaymentsLoading(false);
    }
  };

  const handlePayments = (event, newPayment) => {
    if (newPayment !== null) {
      setPayment(newPayment);
    }

    // console.log(newPayment);
  };

  useEffect(() => {
    fetchPayments();

    fetchOrders();

    document.title = "Payment - Binro";
  }, []);
//rendering the page elements
  return (
    <Box className="flex">
      <main className="grid grid-cols-1 gap-y-7 gap-x-5 w-full overflow-x-hidden p-5 pb-[100px]">
        <Box
          className="fixed right-0"
          sx={{
            width: {
              xs: "100%",
              md: `calc(100% - ${sidebarWidth}px)`,
            },
            top: { xs: "48px", sm: "64px" },
            "& .MuiLinearProgress-root": {
              backgroundColor: "transparent",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#D4024B",
            },
            zIndex: 10,
          }}
        >
          <LinearProgress variant="determinate" value={200 / 3} />
        </Box>

        <Box component="section" className="payment-section main-paper p-6">
          <Box className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-[41%_59%] lg:w-[calc(100%_-_1.25rem)] gap-5">
            <Box className="payment-section-invoice p-4">
              <Box className="section-title invoice-title mb-10">
                <Typography
                  variant="subtitle1"
                  className="section-title-tpg"
                  fontWeight={500}
                >
                  Invoice
                </Typography>
              </Box>
              <List className="border-b-2 border-[#E6E6E6]" disablePadding>
                <ListItem className="inline-block mb-4" disablePadding>
                  <span className="mr-2 font-semibold">
                    {pages.length} {pages.length === 1 ? "Page" : "Pages"}
                  </span>
                  (
                  {Array.from(pages.values(), (user) => user.username).join(
                    ", "
                  )}
                  )
                </ListItem>
                <ListItem className="inline-block mb-4" disablePadding>
                  <span className="mr-2 font-semibold">
                    {features.length}{" "}
                    {pages.length === 1 ? "Feature" : "Features"}
                  </span>
                  (
                  {Array.from(
                    features.values(),
                    (feature) => feature.name
                  ).join(", ")}
                  )
                </ListItem>
              </List>
              <Box className="pt-4">
                <Box className="final-cost flex items-center gap-4 mb-4">
                  <Typography
                    sx={{
                      // textDecoration: finalPrice !== totalPrice && "line-through",
                      // opacity: finalPrice !== totalPrice && "0.5",
                      textDecoration:
                        totalPrice > 0 && credit > 0 && pages.length <= credit
                          ? "line-through"
                          : "none",

                      opacity:
                        totalPrice > 0 && credit > 0 && pages.length <= credit
                          ? "0.5"
                          : "1",
                    }}
                    fontSize={26}
                    fontWeight={600}
                  >
                    <span className="text-base align-top inline-block mt-[5px]">
                      $
                    </span>
                    {totalPrice}
                  </Typography>
                </Box>
                <Paper
                  onSubmit={checkCoupon}
                  component="form"
                  className="shadow-none"
                >
                  <Box className="flex items-center bg-[#F9F9F9] rounded-lg mb-4 w-full">
                    <input
                      ref={couponInputRef}
                      type="text"
                      placeholder="Coupon Discount / Appsumo purchase"
                      className="flex-1 w-full text-sm px-5 py-[13px] bg-transparent outline-none"
                      disabled={!finalPrice}
                      required
                    />
                    <Button
                      className="bg-[#D4024B] text-white min-w-[50px] w-[50px] h-[50px] p-0 rounded-none rounded-tr-lg rounded-br-lg"
                      type="submit"
                      disabled={couponLoading || !finalPrice}
                    >
                      {couponLoading ? (
                        <CircularProgress
                          sx={{
                            circle: {
                              stroke: "#fff",
                            },
                          }}
                          size={22}
                        />
                      ) : (
                        <Check />
                      )}
                    </Button>
                  </Box>
                  <FormHelperText>{couponError && couponError}</FormHelperText>
                </Paper>
              </Box>
              {finalPrice !== totalPrice && (
                <Box className="pt-4 border-t-2 border-[#E6E6E6]">
                  <Box>
                    <Typography className="mb-4" fontSize={26} fontWeight={600}>
                      {finalPrice} $
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      Number of use coupons (based on the number of selected
                      page):
                      <span className="font-bold ml-1">{useCoupon}</span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="text-[#D4024B]" variant="caption">
                      Number of use coupons after pay:
                      <span className="font-bold ml-1">
                        {useCoupon - pages.length}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              )}

              {useCode ? (
                ""
              ) : totalPrice > 0 && pages.length <= credit ? (
                <>
                  <Typography fontSize={26} fontWeight={600}>
                    <span className="text-base align-top inline-block mt-[5px]">
                      $
                    </span>
                    0
                  </Typography>
                  <Typography className="text-[#102132]" variant="caption">
                    Credit after pay:
                    <span className="font-bold ml-1 text-[#D4024B]">
                      {credit - pages.length}
                    </span>
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Box>

            <Box className="payment-section-gateway flex flex-col justify-center items-center border-[3px] border-[#FCFCFC]">
              <Box className="choose-gateway w-11/12 md:w-4/5 p-4">
                {/* <Box className="flex items-center p-2.5 mt-4 text-[#D4024B] font-medium border border-[#D4024B] text-center rounded-lg bg-[#D4024B1A] mx-auto mb-4">
                  <Typography fontSize={14}>
                    Buy a lifetime account on
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://appsumo.com/products/binro?utm_source=partner-link&utm_medium=referral&utm_campaign=partner-182516"
                      className="font-bold mx-2 underline"
                    >
                      Appsumo
                    </a>
                    with special features and
                    <span className="font-bold">69% discount</span> for every
                    Instagram page analysis
                  </Typography>
                </Box> */}
                <Box className="md-title choose-gateway-title mb-4">
                  <Typography
                    className="section-title"
                    variant="subtitle1"
                    fontWeight={500}
                  >
                    Select a payment method
                  </Typography>
                </Box>
                <Box className="gateways mb-5">
                  <FormControl className="w-full">
                    {paymentsLoading ? (
                      <Box className="flex flex-col justify-center gap-2.5">
                        <Skeleton
                          variant="rectangular"
                          className="h-20 rounded-lg"
                        />
                        <Skeleton
                          variant="rectangular"
                          className="h-20 rounded-lg"
                        />
                      </Box>
                    ) : (
                      <ToggleButtonGroup
                        color="primary"
                        exclusive
                        value={payment}
                        onChange={handlePayments}
                        aria-label="Payments"
                        className="flex flex-col justify-center gap-2.5"
                      >
                        {payments.map((payment) => (
                          <ToggleButton
                            key={payment.id}
                            className="payment-box min-h-[80px] justify-start bg-[#F9F9F9] rounded-lg border-none py-3 flex-1"
                            value={payment}
                            sx={{
                              border: "3px solid transparent!important",
                              "&.Mui-selected": {
                                borderColor: "#D4024B!important",
                                backgroundColor: "#D4024B1A!important",
                              },
                            }}
                          >
                            <Box className="flex items-center gap-x-4">
                              <Box
                                className="payment-icon flex justify-center items-center w-14 h-12"
                                dangerouslySetInnerHTML={{
                                  __html: payment.icon,
                                }}
                                sx={{
                                  svg: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                }}
                              ></Box>
                              <Box className="payment-caption">
                                <Typography className="text-xs text-left normal-case">
                                  {payment.description}
                                </Typography>
                              </Box>
                            </Box>
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    )}

                    {paymentsError && paymentsError}
                  </FormControl>
                </Box>
                <Box className="pay-now flex justify-center">
                  {/* <Link to="/competitor-analysis/result"> */}
                  <Button
                    className="bg-[#D4024B] rounded-3xl h-12 px-9 shadow-none capitalize"
                    startIcon={
                      payLoading ? (
                        <CircularProgress
                          sx={{
                            circle: {
                              stroke: "#fff",
                            },
                          }}
                          size={22}
                        />
                      ) : (
                        <CreditCard className="fill-white" />
                      )
                    }
                    variant="contained"
                    onClick={payHandler}
                    disabled={payLoading}
                  >
                    Pay Now
                  </Button>
                  {/* </Link> */}
                </Box>{" "}
                {payError && (
                  <Box className="py-4">
                    <FormHelperText className="text-center">
                      {payError}
                    </FormHelperText>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </main>
    </Box>
  );
};

export default Payment;
