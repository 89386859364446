import { callApi } from "./httpClients";

export async function adminReports() {
  return callApi("admin-reports/", null, "get");
}

export async function adminOrders() {
  return callApi("admin-orders/", null, "get");
}

export async function reports() {
  return callApi("reports/", null, "get");
}

export async function signUp(data) {
  return callApi("signup/", data, "post");
}

export async function login(data) {
  return callApi("login/", data, "post");
}
export async function forgotPass(data){
  return callApi("forgot-password/", data, "post")
}
export async function passComplete(data){

  return callApi("forgot-password/confirm/",data, "post")
}
export async function getSession(url){
  return callApi(`forgot-password/confirm/${url}/`, null , "get")
}
export async function features() {
  return callApi("features/", null, "get");
}

export async function getUser() {
  return callApi("get-user/", null, "get");
}

export async function payments() {
  return callApi("payments/", null, "get");
}

export async function updateUser(data) {
  return callApi("update-user/", data, "put");
}

export async function resetPassword(data) {
  return callApi("reset-password/", data, "put");
}

export async function checkCode(data) {
  return callApi(`check-code/${data.code}`, data, "get");
}

export async function order(data) {
  return callApi("order/", data, "post");
}

export async function orders() {
  return callApi("orders/", null, "get");
}

export async function report(data) {
  return callApi("report/", data, "post");
}

export async function reanalysis(data) {
  return callApi(`reanalysis-report/${data.report_id}/`, data, "post");
}

export async function logout() {
  return callApi("logout/", null, "post");
}
export async function successfulPayment(url) {
  return callApi(`stripe/success/${url}/`, null, "get")
}
export async function cancelledPayment(url) {
  return callApi(`stripe/cancelled/${url}/`, null, "get")
}
export async function emailVerify(){
  return callApi("email-verify", null, 'post')

}
export async function calculate(data){
  return callApi("calculate-price/", data, 'post')
}

export async function approveCredit(report_id){
  return callApi(`report-payment/${report_id}/`, null, 'get')
}
export async function  getPackages(){
  return callApi("packages/", null, "get")
}
export async function instagramUser(instaUsername){
  return callApi( `instagram_search/?username=${instaUsername}`, null, 'get')
}
export async function fetchFollowersInfo(report_id){
  return callApi(`followers-list/${report_id}/`, null,"get")
}